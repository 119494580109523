import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";

export default class Sitemap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
          <>
            <Header />
            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}
            <rs-module-wrap id="aboutus_pg">
              <div className="inner_hdr_section">                     
                <h1 className="title">CONTACT US</h1>                                        
              </div>
            </rs-module-wrap>
            <div className="section-title mt-5 mb-5">
              <div className="container">
                <h6 className="text-muted text-left mb-3">Website Pages</h6>
                <div className="row sitemap-web">
                  <div className="col-12 col-md-6">
                    <ul className="list-group text-left">
                      <li className="list-group-item list-group-item-action ">
                        <a href={"/about-us"}>
                          <i
                            className="fa fa-circle-thin text-yellow mx-2"
                            aria-hidden="true"
                          ></i>
                          About Us{" "}
                        </a>
                      </li>
                      <li className="list-group-item list-group-item-action">
                        <a href={"/services"}>
                          <i
                            className="fa fa-circle-thin text-yellow mx-2"
                            aria-hidden="true"
                          ></i>
                          Services
                        </a>
                      </li>
                      <li className="list-group-item list-group-item-action">
                        <a href={"/pricing"}>
                          <i
                            className="fa fa-circle-thin text-yellow mx-2"
                            aria-hidden="true"
                          ></i>
                          Prices
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-6">
                    <ul className="list-group text-left">
                      <li className="list-group-item list-group-item-action">
                        <a href={"/events"}>
                          <i
                            className="fa fa-circle-thin text-yellow mx-2"
                            aria-hidden="true"
                          ></i>
                          Events
                        </a>
                      </li>
                      <li className="list-group-item list-group-item-action">
                        <a href={"/contact-us"}>
                          <i
                            className="fa fa-circle-thin text-yellow mx-2"
                            aria-hidden="true"
                          ></i>
                          Contacts
                        </a>
                      </li>
                      <li className="list-group-item list-group-item-action">
                        <a href={"/privacy-policy"}>
                          <i
                            className="fa fa-circle-thin text-yellow mx-2"
                            aria-hidden="true"
                          ></i>
                          Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="pvcpol_pg"><Footer /></div>
          </>
    );
  }
}
