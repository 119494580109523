import { useEffect, useState } from "react"
import InputField from "./contactform/InputField";
import TextareaField from "./contactform/TextareaField";
/* import { ChevronRightIcon } from '@heroicons/react/solid' */
/* import Captcha from "../components/common/Captcha"; */
import emailjs from 'emailjs-com';


export const ContactsForm = () => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',    
  });
  const [status, setStatus] = useState('');
 

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.send("service_w1w6f1s",
    "template_odq49vr",
    values,
    "a1170giFZgBo8V2Xg")
      .then(response => {
        console.log('SUCCESS!', response);
        setValues({
          firstName: '',
    lastName: '',
    email: '',
    message: '',       
        
        });
        setStatus('SUCCESS');
      }, error => {
        console.log('FAILED...', error);
      });
  }

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 50000);
    }
  }, [status]);

  const handleChange = (e) => {
    setValues(values => ({
      ...values,
      [e.target.name]: e.target.value
    }))
  }
  return (
    <div className="webform-client-form webform-client-form-35 getquotesideform">
      <form onSubmit={handleSubmit} id="webform-client-form-36"  className="fcf-form-class contactform"> 
      <div className="fcf-form-group field formfield">
         <InputField value={values.firstName} handleChange={handleChange} name="firstName" type="text" placeholder="Enter Your First Name" />
        <InputField value={values.lastName} handleChange={handleChange} name="lastName" type="text" placeholder="Enter Your Last Name" />
        <InputField value={values.email} handleChange={handleChange} name="email" type="email" placeholder="Enter Your Email Address" />    
        </div>
         <TextareaField value={values.message} handleChange={handleChange} name="message" placeholder="Enter Your Message" />
        <div className="btn_sec"> <button type="submit" className="webform-submit button-primary btn btn-primary form-submit fcf-btn fcf-btn-primary fcf-btn-lg fcf-btn-block submit_btn">SUBMIT</button></div>
      </form>
      {status && renderAlert()}
    </div>
  )
}

const renderAlert = () => (
  <div className="successmsg">
    <p>Your message submitted successfully</p>
  </div>
)


export default ContactsForm;
