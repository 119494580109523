import React, { Component } from "react";




export default class Footer extends Component {
render() {
    return (
      <>
  



<div className="section mcb-section mcb-section-qk1podr6h  full-width" >
        <div className="section_wrapper mcb-section-inner">
          <div className="wrap mcb-wrap mcb-wrap-rov7zrf41 one  valign-top clearfix">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-2denarsdr one column_fancy_divider">
                <div className="fancy-divider">
                  <svg preserveAspectRatio="none" viewBox="0 0 100 100" height={100} width="100%" version="1.1" xmlns="https://www.w3.org/2000/svg" style={{background: '#ffffff'}}>
                    <path d="M0 100 L50 2 L100 100 Z" style={{fill: '#0d0f39', stroke: '#0d0f39'}} />
                  </svg></div>
              </div>
            </div>
          </div>
        </div>
      </div> 

      <footer id="Footer" className="clearfix ">
        <div className="widgets_wrapper ">
          <div className="container">
            <div className="column one column_column callaction">
              <p>At our VRAI live events, come meet the team, bring your devices and let us do the work. <br />
                If you need any assistance, chat with us 24/7.</p>
              <hr />
              {/* <div className="column mcb-column mcb-item-snbbq11k0 one column_button">
                <a className="button  button_size_2" href="https://www.infotechsector.exchange/">
                  <span className="button_label">Chat With Us Now</span>
                </a>
              </div> */}
            </div>
          </div>
          <hr className="space" />
          <div className="container">
            <div className="column one-third">
              <aside id="custom_html-2" className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <h6>ABOUT</h6>
                    <p>VRAI Technologies, exclusively on the 10X Cyber Marketplace is the World's first Cyber Privacy and Protection Provider that leverages Artificial Intelligence, Big Data, Compaction and Blockchain technologies to Privatize your Personal Data, Protect your Devices and Networks all while Optimizing computer Speed and Performance.</p>  
                </div>
              </aside>
            </div>
           <div className="column one-third ftr_link">
              <aside id="custom_html-3" className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <h6>QUICK LINKS</h6>
                  <ul>
                    <li><a href="about-us">About Us</a></li>
                    <li><a href="contact-us">Contact Us</a></li>
                    <li><a href="privacy-policy">Privacy Policy</a></li>
                    <li><a href="sitemap">Sitemap</a></li>
                  </ul>
                </div>
              </aside>
            </div>  
              <div className="column one-third">
              <aside id="custom_html-4" className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <h6>CONTACT US</h6>
                  <p>support@vrai.tech</p>
                  <p>+1 (512) 767-1773</p>
                  <p>10815 Ranch Rd 2222, Building 3B Suite 100-527, Austin, TX 78730.</p>
                </div>
              </aside>
            </div>  
          </div>  
        </div>
         <div className="footer_copy">
          <div className="container">
            <div className="column one-third">
              <div className="copyright">© <a href="/" target="_blank">VRAI Technologies</a> 2022</div>          
            </div>

            <div className="column two-third">
        
              <a href="https://www.facebook.com/CrowdPoint-Technologies-HQ-106496614487103">
                  <i className="fa fa-facebook" aria-hidden="true"></i></a>
              <a href="https://twitter.com/crowdpoint_tech?lang=en">
                <i className="fa fa-twitter" aria-hidden="true"></i></a>
              <a href="https://www.linkedin.com/company/crowdpointtech/">
                <i className="fa fa-linkedin" aria-hidden="true"></i></a>              
            </div>

          </div>
        </div> 
      </footer>
 
</>

    );
  }
}
