import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Home from "../../Pages/Home";
import Services from "../../Pages/Services";
import Events from "../../Pages/Events";
import Price from "../../Pages/Price";
import ContactUs from "../../Pages/ContactUs";
import UpcomingEvent from "../../Pages/upcomingevent";
import AboutUs from "../../Pages/About-Us";
import Contribute from "../../Pages/Contribute";
import Policy from "../../Pages/Policy";
import Sitemap from "../../Pages/Sitemap";
import AfterInstall from "../../Pages/AfterInstall";
import InstallInstructions from "../../Pages/InstallInstructions";


export default function Routes() {
  return (
    <>
    <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/pricing" element={<Price />} />
      <Route path="/events" element={<Events />} />
      <Route path="/upcoming-events" element={<UpcomingEvent />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contribute" element={<Contribute />} />
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/sitemap" element={<Sitemap />} />
      <Route path="/after-install" element={<AfterInstall />} />
      <Route path="/install-instructions" element={<InstallInstructions />} />
    </Switch>
    <script src="https://cart.crwd.market/tools/productpopup.js"></script>
    </>
  );
}
