import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import icon1 from "../Assets/img/vrai/data-icon1.png";
import homepic from "../Assets/img/vrai/data-home-pic2.png";
import homepic2 from "../Assets/img/vrai/data-home-pic1.png";
import darklogo from "../Assets/img/vrai/dark-logo.png";



export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: "",
      showModel: false,
    };
    this.showVideoPop = this.showVideoPop.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => this.setState({ showModel: false });
  showVideoPop = (src) => {
    this.setState({ showModel: true, video: src });
  };
  render() {
    return (
      <>




<div className="section mcb-section pt110 equal-height-wrap txt_center_resp">
        <div className="section_wrapper mcb-section-inner">
          <div className="wrap mcb-wrap hm_about_sec one-second  valign-middle clearfix">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-x9clk3qj8 one column_column">
                <div className="column_attr clearfix">
                  <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                    <div className="image_wrapper">
                      <img className="scale-with-grid" src={icon1} alt="data-icon1" />
                    </div>
                  </div>
                  <hr className="no_line hr_spc" />
                  <h6>ABOUT US</h6>
                  <h2>VRAI: NexGen Cyber Security Privacy And Protection
                    Suite</h2>													
                </div>
              </div>
              <div className="column mcb-column mcb-item-azdiv5vog one column_column">
                <div className="column_attr clearfix">
                <p>While technologies provide an environment for getting things done in a more healthy, efficient, productive manner, it has also created a new scenario of complexity and vulnerability, which inherently can result in devastating consequences. As smart device use soars, so does the risk of being watched, tracked or hacked. Users are accustomed to trying to protect themselves through now antiquated cybersecurity softwares which are far from effective in protecting against the vulnerabilities of surveillance capitalism and Internet of Things (IoT).</p>
                  <p>VRAI Technologies (VRAI) predicted this gap and now is the catalyst provider bringing an enterprise level of privacy, performance and protection to the masses. Our in-depth and holistic approach to cybersecurity protects your privacy in the digital domain and physical world. VRAI mitigates vulnerabilities before an attacker can gain access.</p>
                  <p>Our mission is to unite Consumers and SMB with this award winning suite that Privatizes your Personal Data, Protects your Devices and Networks all while Optimizing computer Speed and Performance. VRAI is exclusively on the 10X Cyber Marketplace, powered by CrowdPoint Technologies.</p>
                  </div>
              </div>
            </div>
          </div>
          <div className="wrap mcb-wrap hm_about_sec one-second valign-middle ">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-yewgb22pn one column_image">
                <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                  
                <Modal show={this.state.showModel} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>VRAI Videos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              src={this.state.video}
              frameborder="0"
              allow="autoplay;"
              width="850"
              height="500"
              title="video"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              X
            </Button>
            {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
                  <div className="waves-box">
                    <a
                      href="javascipt:void(0);"
                      className="iq-video popup-video mfp-iframe "
                      onClick={() =>
                        this.showVideoPop(
                          "https://players.brightcove.net/6264577224001/default_default/index.html?videoId=6283209420001"
                        )
                      }
                    >
                      {" "}
                      <i className="fa fa-play"></i>
                    </a>

                    <div className="iq-waves">
                      <div className="waves wave-1"></div>

                      <div className="waves wave-2"></div>

                      <div className="waves wave-3"></div>
                    </div>
                  </div>
                  
                  <div className="image_wrapper"><img className="scale-with-grid" src={homepic} alt="data-home-pic2" width={780} height={851} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section mcb-section mcb-section-wg691o6i3  equal-height-wrap txt_center_resp" style={{paddingBottom: '70px'}}>
        <div className="section_wrapper mcb-section-inner">
          <div className="wrap mcb-wrap mcb-wrap-0lhpz2c9j one-second  valign-middle clearfix" style={{padding: '0px 1%', height: '703px'}}>
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-uwie7e026 one column_image">
                <div className="image_frame image_item no_link scale-with-grid aligncenter no_border">
                  <div className="image_wrapper"><img className="scale-with-grid" src={homepic2} alt="data-home-pic1" width={780} height={586} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap mcb-wrap mcb-wrap-gkdljrgda one-second  valign-middle clearfix" style={{padding: '0px 1%', height: '703px'}}>
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-llma1ny1o one column_column">
                <div className="column_attr clearfix">
                  <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={icon1} alt="data-icon1" width={15} height={16} /></div>
                  </div>
                  <hr className="no_line" style={{margin: '0 auto 5px auto'}} />
                  <h6>VRAI ANYWARE</h6>
                  <h2>EXPERIENCE</h2>
                </div>
              </div>
              <div className="column mcb-column mcb-item-r74hik4xh one-second column_column column-margin-20px">
                <div className="column_attr clearfix">
                  <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={darklogo} alt="data-home-icon1" width={100} height={105} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column mcb-column mcb-item-twuviskq9 one column_column">
                <div className="column_attr clearfix">
                  <p>Now more than ever, hackers are attacking devices on home networks. Whether working from home surfing the internet, talking to Alexa, streaming videos or adjusting the thermostat, your family deserves cyber safety with private connectivity. VRAI mitigates vulnerabilities before an attacker gains access. Easy installation, no technical experience required. Download today. </p>
                </div>
              </div>
              <div className="column mcb-column mcb-item-snbbq11k0 one column_button"><a className="button  button_size_2" href={"/upcoming-events"}><span className="button_label">JOIN US FOR A VRAI EXPERIENCE</span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
       

        

        
      </>
    );
  }
}
