import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";  
import "./Assets/css/bootstrap.min.css";  
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './Components/Common/theme';
import { GlobalStyles } from './Components/Common/global';
import React, { useState } from 'react';
import "./Assets/css/style.css";
import "./Assets/css/vrai/main.css";
import "./Assets/css/vrai/theme.css";
import "./Assets/css/vrai/normal.css"; 
import "./Assets/css/vrai/standard.css"; 
import "./Assets/css/vrai/custom.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Components/Common/Routes";

function App() {
  const [theme, setTheme] = useState('light');
  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
      
    } else {
      setTheme('light');
      
    }
  }
   
  return (

//     <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
//       <>
//         <GlobalStyles />
//         {/* // Pass the toggle functionality to the button */}
       
//           <label class="switch swpos">
  
  
//   <input type="checkbox" onClick={toggleTheme} />
//   <span class="slider round"></span> 
// </label> 
         
<BrowserRouter>
      <Routes />
    </BrowserRouter>
      // </>
    // </ThemeProvider>
  );
}

export default App;
