import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";


export default class Contribute extends Component {
  render() {
    return (
      <>
      <script src="https://affiliate.cbex.io/affiliate.js"></script>
        <Header />
        <header class="pages-banner">
          <div class="container"></div>
        </header>
        <div class="section-title mt-5 mb-5">
          <div class="container">
            <h2> Need Contribute page text here</h2>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
