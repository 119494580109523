import React, { Component } from "react";
import checkmark from "../Assets/img/vrai/check-mark.png";
import group from "../Assets/img/vrai/group.png";
import award from "../Assets/img/vrai/award.png";
import CountUp from "react-countup";

export default class WhatWeAchieved extends Component {
  render() {
    return (
      
      
            <div className="section mcb-section counter_num">
              <div className="section_wrapper mcb-section-inner">
                <div className="wrap mcb-wrap one-third valign-top ">
                  <div className="mcb-wrap-inner">
                    <div className="column mcb-column one column_quick_fact">
                      <div className="quick_fact align_ animate-math">
                        <img src={checkmark} alt="" />

                        <h3 className="number-wrapper" data-count="1000">
                      <CountUp start={0} end={1000} delay={0} duration="3">
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </h3>

                        {/* <div className="number-wrapper">
                          <span className="number" data-to={1000}>1000</span>
                        </div> */}
                        {/* <hr className="hr_narrow" /> */}
                        <div className="desc">
                          <p>Partners</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrap mcb-wrap one-third valign-top ">
                  <div className="mcb-wrap-inner">
                    <div className="column mcb-column one column_quick_fact">
                      <div className="quick_fact align_ animate-math">
                        <img src={group} alt="" />

                        <h3 className="number-wrapper" data-count="4000">
                      <CountUp start={0} end={4000} delay={0} duration="3">
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </h3>
                       {/*  <div className="number-wrapper">
                          <span className="number" data-to={4000}>4000</span>
                        </div>
                        <hr className="hr_narrow" /> */}
                        <div className="desc">
                          <p>Happy Customers</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="wrap mcb-wrap one-third valign-top ">
                  <div className="mcb-wrap-inner">
                    <div className="column mcb-column one column_quick_fact">
                      <div className="quick_fact align_ animate-math">
                        <img src={award} alt="" />

                        <h3 className="number-wrapper" data-count="1000">
                      <CountUp start={0} end={1000} delay={0} duration="3">
                        {({ countUpRef }) => (
                          <div>
                            <span ref={countUpRef} />
                          </div>
                        )}
                      </CountUp>
                    </h3>
                        {/* <div className="number-wrapper"><span className="number" data-to={1000}>1000</span>
                        </div>
                        <hr className="hr_narrow" /> */}
                        <div className="desc">
                          <p>Champion Technicians</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>									
              </div>
            </div>
        
    );
  }
}
