import React, { Component } from "react";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import icon1 from "../Assets/img/vrai/data-icon1.png";
import privacyplan from "../Assets/img/vrai/privacyplan.png";
import privacyplanperformance from "../Assets/img/vrai/privacyplan-performance.png";
import p3planpurplebg from "../Assets/img/vrai/p3plan-purplebg.png";
import p3plussuite from "../Assets/img/vrai/homeplussecuritysuits-blue-bg.png";
import whitecheck from "../Assets/img/vrai/ligth-gray-check-icon.png";
import graycheck from "../Assets/img/vrai/gray-check-icon.png";
import purplecheck from "../Assets/img/vrai/purple-check-icon.png";
import bluecheck from "../Assets/img/vrai/blue-check-icon.png";
import removeicon from "../Assets/img/vrai/remove-icon.png";

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productUrl: "",
      activePlan: "plan4",
    };
    this.setProduct = this.setProduct.bind(this);
  }
  setProduct = (data) => {
    this.setState({
      productUrl: data,
    });
  };
  setActivePlan = (plan) => {
    this.setState({ activePlan: plan });
  };
  render() {
    return (
      <>
        <div className="section mcb-section plan-section">
          <div className="section_wrapper mcb-section-inner pricing_container">
            <div className="wrap mcb-wrap mcb-wrap-4dh9er3oi one  valign-top clearfix">
              <div className="mcb-wrap-inner">
                <div className="column one hmpd">
                  <div className="column_attr clearfix align_center">
                    <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                      <div className="image_wrapper"><img className="scale-with-grid" src={icon1} alt="data-icon1" width={15} height={16} /></div>
                    </div>
                    <hr className="no_line hr_spc" />
                    <h6>OUR BEST PLANS</h6>
                    {/* <h2 className="plantitle">Plan</h2> */}
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
        <Tabs defaultActiveKey="yearly" id="uncontrolled-tab-example" className="portfo_tabs">
          {/* Monthly Plan */}
          <Tab eventKey="monthly" title="Monthly Plan">            
            <section id="price" className="price-area">
              <div className="container pricing_container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 price-item table_wrap" >
                    <div className="single-price-item">
                      <div className="pricing-body">
                        <div className="table_format">
                          <div className="tableoption">
                            <ul>
                              <li>Personal data protection</li>
                              <li>Decentralized ID</li>
                              <li>24/7 Tech support chat</li>
                              <li>Optimizes device storage space</li>
                              <li>Deletes privacy traces for increased security</li>
                              <li>Improved boot time by removing startup apps</li>
                              <li>Removes temporary files, cache files and log files</li>
                              <li>Identifies and removes redundant data</li>
                              <li>Next-gen A.I. cyber threat protection</li>
                              <li>Anti-spyware</li>
                              <li>Prevents cyber threats from exposing your sensitive data</li>
                              <li>Online and offline cyber security</li>
                              <li>Prevent advanced malware, ransomware, trojans, and viruses</li>
                              <li>Malicious website protection</li>
                              <li>Wi-Fi & wired networks automatically secured</li>
                              <li>Guards computers, devices, and the internet of things</li>
                              <li>Stops Ransomware Attacks In Milliseconds</li>
                              <li>No Device Updating Require</li>
                              <li>Continuous Remote Exploits Update</li>
                              <li>24/7 Network Device InsideSystem Monitoring + Protection</li>
                              <li>Home Security Dashboard with Monitor Free Training</li>
                              <li>Identify and Terminate Threats</li> 
                              <li>Ai Driven Exploit Detection & Response</li>
                              <li>Automatically Deploys Counter Measures</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan1"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan1")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2021-12-10-ITX-CPM-vrai-001?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item table_format lightitem">
                        <div>
                          <div className="image_frame whitebg image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper"><img className="scale-with-grid" src={privacyplan} alt="data-home-pic3" /></div>
                          </div>
                          <div className="pricing-header pricetabletitle">
                            <hr className="no_line" />
                            <p>Privatize Your Identity Data</p>
                            <div className="semititle"><sup className="freedollar">$</sup> Free</div>
                          </div>
                          <div className="pricing-footer">
                          <a href="#!" className="lightgray-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text"></div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li><img src={whitecheck} alt="" /></li>
                            <li><img src={whitecheck} alt="" /></li>
                            <li><img src={whitecheck} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li className="disable">Optimizes device storage space</li>
                            <li className="disable">Deletes privacy traces for increased security</li>
                            <li className="disable">Improved boot time by removing startup apps</li>
                            <li className="disable">Removes temporary files, cache files and log files</li>
                            <li className="disable">Identifies and removes redundant data</li>
                            <li className="disable">Next-gen A.I. cyber threat protection</li>
                            <li className="disable">Anti-spyware</li>
                            <li className="disable">Prevents cyber threats from exposing your sensitive data</li>
                            <li className="disable">Online and offline cyber security</li>
                            <li className="disable">Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li className="disable">Malicious website protection</li>
                            <li className="disable">Wi-Fi & wired networks automatically secured</li>
                            <li className="disable">Guards computers, devices, and the internet of things</li>
                            <li className="disable">Stops Ransomware Attacks In Milliseconds</li>
                            <li className="disable">No Device Updating Require</li>
                            <li className="disable">Continuous Remote Exploits Update</li>
                            <li className="disable">24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li className="disable">Home Security Dashboard with Monitor Free Training</li>
                            <li className="disable">Identify and Terminate Threats</li> 
                            <li className="disable">Ai Driven Exploit Detection & Response</li>
                            <li className="disable">Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="lightgray-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan2"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan2")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2021-12-10-ITX-CPM-vrai-002?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item active table_format darkitem">
                        <div>
                          <div className="image_frame graybg image_item no_link scale-with-grid no_border" >
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src={privacyplanperformance} alt="data-home-pic4" />
                            </div>
                          </div>
                          <div className="pricetabletitle">
                            <hr className="no_line" />
                            <p>Privatize Identity + Optimize Device Performance</p>
                            <div className="semititle ">$7.00 <small>/mo</small></div>
                          </div>
                          <div className="pricing-footer">
                          <a href="#!" className="dark-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text"></div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={removeicon} alt="" /> </li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li>Optimizes device storage space</li>
                            <li>Deletes privacy traces for increased security</li>
                            <li>Improved boot time by removing startup apps</li>
                            <li>Removes temporary files, cache files and log files</li>
                            <li>Identifies and removes redundant data</li>
                            <li className="disable">Next-gen A.I. cyber threat protection</li>
                            <li className="disable">Anti-spyware</li>
                            <li className="disable">Prevents cyber threats from exposing your sensitive data</li>
                            <li className="disable">Online and offline cyber security</li>
                            <li className="disable">Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li className="disable">Malicious website protection</li>
                            <li className="disable">Wi-Fi & wired networks automatically secured</li>
                            <li className="disable">Guards computers, devices, and the internet of things</li>
                            <li className="disable">Stops Ransomware Attacks In Milliseconds</li>
                            <li className="disable">No Device Updating Require</li>
                            <li className="disable">Continuous Remote Exploits Update</li>
                            <li className="disable">24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li className="disable">Home Security Dashboard with Monitor Free Training</li>
                            <li className="disable">Identify and Terminate Threats</li> 
                            <li className="disable">Ai Driven Exploit Detection & Response</li>
                            <li className="disable">Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="dark-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan3"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan3")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2021-12-10-ITX-CPM-vrai-003?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item table_format purpleitem">
                        <div>
                          <div className="image_frame purplebg image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper"><img className="scale-with-grid" src={p3planpurplebg} alt="data-home-pic6" />
                            </div>
                          </div>
                          <div className="pricetabletitle">
                            <hr className="no_line" />
                            <p>Privacy, Performance + Device Protection</p>
                            <div className="semititle purpleclr"> $12.99 <small>/mo</small></div>
                          </div>
                          <div className="pricing-footer">
                          <a href="#!" className="purple-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text"></div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /> </li>
                            <li> <img src={removeicon} alt="" /></li>
                            <li> <img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li>Optimizes device storage space</li>
                            <li>Deletes privacy traces for increased security</li>
                            <li>Improved boot time by removing startup apps</li>
                            <li>Removes temporary files, cache files and log files</li>
                            <li>Identifies and removes redundant data</li>
                            <li>Next-gen A.I. cyber threat protection</li>
                            <li>Anti-spyware</li>
                            <li>Prevents cyber threats from exposing your sensitive data</li>
                            <li>Online and offline cyber security</li>
                            <li>Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li>Malicious website protection</li>
                            <li className="disable">Wi-Fi & wired networks automatically secured</li>
                            <li className="disable">Guards computers, devices, and the internet of things</li>
                            <li className="disable">Stops Ransomware Attacks In Milliseconds</li>
                            <li className="disable">No Device Updating Require</li>
                            <li className="disable">Continuous Remote Exploits Update</li>
                            <li className="disable">24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li className="disable">Home Security Dashboard with Monitor Free Training</li>
                            <li className="disable">Identify and Terminate Threats</li> 
                            <li className="disable">Ai Driven Exploit Detection & Response</li>
                            <li className="disable">Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="purple-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan4"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan4")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2021-12-10-ITX-CPM-vrai-004?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item bswrapzzz table_format bluebgitem">
                        <div className="image_frame bluebg image_item no_link scale-with-grid no_border">
                          <div className="image_wrapper"><img className="scale-with-grid" src={p3plussuite} alt="data-home-pic6" />
                          </div>
                        </div>
                        <div className="pricetabletitle">
                          <hr className="no_line" />
                          <p>Privacy, Performance Device, Network + IoT Protection</p>
                          <div className="semititle blueclr">$54.99 <small>/mo</small></div>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="blue-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text"></div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li>Optimizes device storage space</li>
                            <li>Deletes privacy traces for increased security</li>
                            <li>Improved boot time by removing startup apps</li>
                            <li>Removes temporary files, cache files and log files</li>
                            <li>Identifies and removes redundant data</li>
                            <li>Next-gen A.I. cyber threat protection</li>
                            <li>Anti-spyware</li>
                            <li>Prevents cyber threats from exposing your sensitive data</li>
                            <li>Online and offline cyber security</li>
                            <li>Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li>Malicious website protection</li>
                            <li>Wi-Fi & wired networks automatically secured</li>
                            <li>Guards computers, devices, and the internet of things</li>
                            <li>Stops Ransomware Attacks In Milliseconds</li>
                            <li>No Device Updating Require</li>
                            <li>Continuous Remote Exploits Update</li>
                            <li>24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li>Home Security Dashboard with Monitor Free Training</li>
                            <li>Identify and Terminate Threats</li> 
                            <li>Ai Driven Exploit Detection & Response</li>
                            <li>Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="blue-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="iframeModel"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <iframe className="imgframe"
                        src={this.state.productUrl}
                        width="900"
                        height="600"
                        title={"map"}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>             
          </Tab>
          {/* Monthly Plan */}
          {/* Yearly Plan */}
          <Tab eventKey="yearly" title="Annual Plan">
            <section id="price" className="price-area">
              <div className="container pricing_container">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 price-item table_wrap yrly" >
                    <div className="single-price-item">
                      <div className="pricing-body">
                        <div className="table_format">
                          <div className="tableoption">
                            <ul>
                              <li>Personal data protection</li>
                              <li>Decentralized ID</li>
                              <li>24/7 Tech support chat</li>
                              <li>Optimizes device storage space</li>
                              <li>Deletes privacy traces for increased security</li>
                              <li>Improved boot time by removing startup apps</li>
                              <li>Removes temporary files, cache files and log files</li>
                              <li>Identifies and removes redundant data</li>
                              <li>Next-gen A.I. cyber threat protection</li>
                              <li>Anti-spyware</li>
                              <li>Prevents cyber threats from exposing your sensitive data</li>
                              <li>Online and offline cyber security</li>
                              <li>Prevent advanced malware, ransomware, trojans, and viruses</li>
                              <li>Malicious website protection</li>
                              <li>Wi-Fi & wired networks automatically secured</li>
                              <li>Guards computers, devices, and the internet of things</li>
                              <li>Stops Ransomware Attacks In Milliseconds</li>
                            <li>No Device Updating Require</li>
                            <li>Continuous Remote Exploits Update</li>
                            <li>24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li>Home Security Dashboard with Monitor Free Training</li>
                            <li>Identify and Terminate Threats</li> 
                            <li>Ai Driven Exploit Detection & Response</li>
                            <li>Automatically Deploys Counter Measures</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan1"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan1")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel2"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2021-12-10-ITX-CPM-vrai-001?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item table_format lightitem">
                        <div>
                          <div className="image_frame whitebg image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper"><img className="scale-with-grid" src={privacyplan} alt="data-home-pic3" /></div>
                          </div>
                          <div className="pricing-header pricetabletitle">
                            <hr className="no_line" />
                            <p>Privatize Your Identity Data</p>
                            <div className="semititle frspc"><sup className="freedollar">$</sup> Free</div>
                          </div>
                          <div className="pricing-footer">
                          <a href="#!" className="lightgray-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text"></div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li><img src={whitecheck} alt="" /></li>
                            <li><img src={whitecheck} alt="" /></li>
                            <li><img src={whitecheck} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li className="disable">Optimizes device storage space</li>
                            <li className="disable">Deletes privacy traces for increased security</li>
                            <li className="disable">Improved boot time by removing startup apps</li>
                            <li className="disable">Removes temporary files, cache files and log files</li>
                            <li className="disable">Identifies and removes redundant data</li>
                            <li className="disable">Next-gen A.I. cyber threat protection</li>
                            <li className="disable">Anti-spyware</li>
                            <li className="disable">Prevents cyber threats from exposing your sensitive data</li>
                            <li className="disable">Online and offline cyber security</li>
                            <li className="disable">Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li className="disable">Malicious website protection</li>
                            <li className="disable">Wi-Fi & wired networks automatically secured</li>
                            <li className="disable">Guards computers, devices, and the internet of things</li>
                            <li className="disable">Stops Ransomware Attacks In Milliseconds</li>
                            <li className="disable">No Device Updating Require</li>
                            <li className="disable">Continuous Remote Exploits Update</li>
                            <li className="disable">24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li className="disable">Home Security Dashboard with Monitor Free Training</li>
                            <li className="disable">Identify and Terminate Threats</li> 
                            <li className="disable">Ai Driven Exploit Detection & Response</li>
                            <li className="disable">Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="lightgray-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan2"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan2")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel2"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2022-07-07-ITX-CPM-vrai-006?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item active table_format darkitem">
                        <div>
                          <div className="image_frame graybg image_item no_link scale-with-grid no_border" >
                            <div className="image_wrapper">
                              <img className="scale-with-grid" src={privacyplanperformance} alt="data-home-pic4" />
                            </div>
                          </div>
                          <div className="pricetabletitle">
                            <hr className="no_line" />
                            <p>Privatize Identity + Optimize Device Performance</p>
                            <div className="d-flex justify-content-center align-items-baseline flwr">
                            <div className="regular_price darkclr">$<span className="price_cut">7.00</span></div>
                            <div className="semititle wdt">$4.92<small>/Mo</small></div>
                            <div className="semititle wdt mntpr">$59.00<small>/Year</small></div>
                            </div>
                            <div className="pricing-footer">
                          <a href="#!" className="dark-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text">Billing Every Year</div>
                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={graycheck} alt="" /></li>
                            <li><img src={removeicon} alt="" /> </li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li>Optimizes device storage space</li>
                            <li>Deletes privacy traces for increased security</li>
                            <li>Improved boot time by removing startup apps</li>
                            <li>Removes temporary files, cache files and log files</li>
                            <li>Identifies and removes redundant data</li>
                            <li className="disable">Next-gen A.I. cyber threat protection</li>
                            <li className="disable">Anti-spyware</li>
                            <li className="disable">Prevents cyber threats from exposing your sensitive data</li>
                            <li className="disable">Online and offline cyber security</li>
                            <li className="disable">Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li className="disable">Malicious website protection</li>
                            <li className="disable">Wi-Fi & wired networks automatically secured</li>
                            <li className="disable">Guards computers, devices, and the internet of things</li>
                            <li className="disable">Stops Ransomware Attacks In Milliseconds</li>
                            <li className="disable">No Device Updating Require</li>
                            <li className="disable">Continuous Remote Exploits Update</li>
                            <li className="disable">24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li className="disable">Home Security Dashboard with Monitor Free Training</li>
                            <li className="disable">Identify and Terminate Threats</li> 
                            <li className="disable">Ai Driven Exploit Detection & Response</li>
                            <li className="disable">Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="dark-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan3"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan3")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel2"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2022-07-07-ITX-CPM-vrai-007?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item table_format purpleitem">
                        <div>
                          <div className="image_frame purplebg image_item no_link scale-with-grid no_border">
                            <div className="image_wrapper"><img className="scale-with-grid" src={p3planpurplebg} alt="data-home-pic6" />
                            </div>
                          </div>
                          <div className="pricetabletitle">
                            <hr className="no_line" />
                            <p>Privacy, Performance + Device Protection</p>
                            {/* <div className="d-flex justify-content-center align-items-baseline"> 
                            <div className="regular_price purpleclr"><sup>$</sup><span className="price_cut">179.88</span></div>
                            <div className="semititle purpleclr"><sup>$</sup>149.99 <small>/yr</small></div>
                            </div> */}

                            <div className="d-flex justify-content-center align-items-baseline flwr">
                            <div className="regular_price darkclr">$<span className="price_cut">12.99</span></div>
                            <div className="semititle wdt">$10.75<small>/Mo</small></div>
                            <div className="semititle wdt mntpr purpleclr">$129.00<small>/Year</small></div>
                            </div>
                            
                            <div className="pricing-footer"> 
                          <a href="#!" className="purple-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text">Billing Every Year</div>

                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /></li>
                            <li ><img src={purplecheck} alt="" /> </li>
                            <li> <img src={removeicon} alt="" /></li>
                            <li> <img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                            <li><img src={removeicon} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li>Optimizes device storage space</li>
                            <li>Deletes privacy traces for increased security</li>
                            <li>Improved boot time by removing startup apps</li>
                            <li>Removes temporary files, cache files and log files</li>
                            <li>Identifies and removes redundant data</li>
                            <li>Next-gen A.I. cyber threat protection</li>
                            <li>Anti-spyware</li>
                            <li>Prevents cyber threats from exposing your sensitive data</li>
                            <li>Online and offline cyber security</li>
                            <li>Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li>Malicious website protection</li>
                            <li className="disable">Wi-Fi & wired networks automatically secured</li>
                            <li className="disable">Guards computers, devices, and the internet of things</li>
                            <li className="disable">Stops Ransomware Attacks In Milliseconds</li>
                            <li className="disable">No Device Updating Require</li>
                            <li className="disable">Continuous Remote Exploits Update</li>
                            <li className="disable">24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li className="disable">Home Security Dashboard with Monitor Free Training</li>
                            <li className="disable">Identify and Terminate Threats</li> 
                            <li className="disable">Ai Driven Exploit Detection & Response</li>
                            <li className="disable">Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="purple-btn">
                            Select Plan
                          </a>
                        </div>
                        
                      </div>
                    </a>
                  </div>
                  <div
                    className={
                      this.state.activePlan === "plan4"
                        ? "col-lg-3 col-md-6 col-sm-12 price-item activeplan mcb-wrap-inner"
                        : "col-lg-3 col-md-6 col-sm-12 price-item mcb-wrap-inner"
                    }
                    onClick={this.setActivePlan.bind(this, "plan4")}
                  >
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeModel2"
                      onClick={() =>
                        this.setProduct(
                          "https://10xcyber.market/product/2022-07-07-ITX-CPM-vrai-008?isEmbedded=1"
                        )
                      }
                    >
                      <div className="single-price-item bswrapzzz table_format bluebgitem">
                        <div className="image_frame bluebg image_item no_link scale-with-grid no_border">
                          <div className="image_wrapper"><img className="scale-with-grid" src={p3plussuite} alt="data-home-pic6" />
                          </div>
                        </div>
                        <div className="pricetabletitle">
                          <hr className="no_line" />
                          <p>Privacy, Performance Device, Network + IoT Protection</p>
                          {/* <div className="d-flex justify-content-center align-items-baseline">
                          <div className="regular_price blueclr"><sup>$</sup><span className="price_cut">779.88</span></div>
                          <div className="semititle blueclr"><sup>$</sup>649.99 <small>/yr</small></div>
                          </div> */}

                          <div className="d-flex justify-content-center align-items-baseline flwr">
                            <div className="regular_price darkclr">$<span className="price_cut">59.99</span></div>
                            <div className="semititle wdt">$47.83<small>/Mo</small></div>
                            <div className="semititle wdt mntpr blueclr">$574.00<small>/Year</small></div>
                            </div>
                            
                            <div className="pricing-footer">
                          <a href="#!" className="blue-btn">
                            Select Plan
                          </a>
                        </div>
                        <div className="small-text">Billing Every Year</div>


                        </div>
                        <div className="pricing-body">
                          <ul className="list">
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li ><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                            <li><img src={bluecheck} alt="" /></li>
                          </ul>
                          <ul className="moblist">
                            <li>Personal data protection</li>
                            <li>Decentralized ID</li>
                            <li>24/7 Tech support chat</li>
                            <li>Optimizes device storage space</li>
                            <li>Deletes privacy traces for increased security</li>
                            <li>Improved boot time by removing startup apps</li>
                            <li>Removes temporary files, cache files and log files</li>
                            <li>Identifies and removes redundant data</li>
                            <li>Next-gen A.I. cyber threat protection</li>
                            <li>Anti-spyware</li>
                            <li>Prevents cyber threats from exposing your sensitive data</li>
                            <li>Online and offline cyber security</li>
                            <li>Prevent advanced malware, ransomware, trojans, and viruses</li>
                            <li>Malicious website protection</li>
                            <li>Wi-Fi & wired networks automatically secured</li>
                            <li>Guards computers, devices, and the internet of things</li>
                            <li>Stops Ransomware Attacks In Milliseconds</li>
                            <li>No Device Updating Require</li>
                            <li>Continuous Remote Exploits Update</li>
                            <li>24/7 Network Device InsideSystem Monitoring + Protection</li>
                            <li>Home Security Dashboard with Monitor Free Training</li>
                            <li>Identify and Terminate Threats</li> 
                            <li>Ai Driven Exploit Detection & Response</li>
                            <li>Automatically Deploys Counter Measures</li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a href="#!" className="blue-btn">
                            Select Plan
                          </a>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="modal fade"
                id="iframeModel2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
              >
                <div
                  className="modal-dialog modal-lg modal-dialog-centered"
                  role="document"
                >
                  <div className="modal-content">
                    <div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <iframe className="imgframe"
                        src={this.state.productUrl}
                        width="900"
                        height="600"
                        title={"map"}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Yearly Plan */}
          </Tab>
        </Tabs>
      </>
    );
  }
}
