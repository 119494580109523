import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
// import Loader from "../Assets/img/White-Background.gif";
// import { getOrders } from "../utils/apis/orders";
import WhatWeProvide from "../Components/WhatWeProvide";
import AboutUsHome from "../Components/AboutUsHome";
import WhatWeAchieved from "../Components/WhatWeAchieved";
import Pricing from "../Components/Pricing";
import Testimonials from "../Components/Testimonals";
import Award from "../Components/Awards";


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 1000);
  }
  async componentDidMount() {
    this.getData();
    // await getOrders()
  }
  render() {
    return (
      <>
            <div id="Header_wrapper">
              <header id="Header">
                {/* <div className="header_placeholder" /> */}
                <Header />


                {/* <div className="mfn-main-slider"> */}

                <rs-module-wrap id="rev_slider_1_1_wrapper" data-source="gallery">
                  <div className="hdr_section">
                    <h1 className="title">VRAI ANYWARE</h1>
                    <div className="hdr_content">
                      <p>Award-Winning Privacy, Performance and Protection for Identity, Device and Network</p>
                    </div>
                    <a className="booknow-btn" href="Pricing">BUY NOW</a>
                  </div>
                </rs-module-wrap>
                {/* </div> */}
              </header>
            </div>
            <div id="Content">
              <div className="content_wrapper clearfix">
                <div className="sections_group">
                  <div className="entry-content">
                    <div className="mfn-builder-content">
                      <AboutUsHome />
                      <div className="section mcb-section mcb-section-pl1a7c4cg  full-width">
                        <div className="section_wrapper mcb-section-inner">
                          <div className="wrap mcb-wrap mcb-wrap-uz2r2npmu one  valign-top clearfix">
                            <div className="mcb-wrap-inner">
                              <div className="column mcb-column mcb-item-i5m78knas one column_fancy_divider">
                                <div className="fancy-divider">
                                  <svg preserveAspectRatio="none" viewBox="0 0 100 100" height={100} width="100%" version="1.1" xmlns="https://www.w3.org/2000/svg">
                                    <path d="M0 100 L50 2 L100 100 Z" style={{ fill: '#ffffff', stroke: '#ffffff' }} />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <WhatWeProvide />
                      <WhatWeAchieved />                      

                      <Award/>

                      <div className="pricetabs"><Pricing /></div>
                      <Testimonials />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
      </>
    );
  }
}
