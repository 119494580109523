import React, { Component } from "react";
import Footer from "../Components/Common/Footer";
import Logo from "../Assets/img/install-imgs/logo-512-512.png";
import DmgImg from "../Assets/img/install-imgs/dmgfile-install.jpg";
import DFSIcon from "../Assets/img/install-imgs/dfsicon-64x64.png";
import LogoWhite from "../Assets/img/logo.png";
import LogoMobile from "../Assets/img/logo-black.png";

export default class InstallInstructions extends Component {
  render() {
    return (
      <div className="install">
        <script src="https://affiliate.cbex.io/affiliate.js"></script>
        <section className="hdrbg_part">
          <h2>Mac App Store: Click a Button to Install an App</h2>
          <img src={Logo} alt="Security Privacy" />
        </section>
        <header className="header-navber-area">
          <nav
            className="navbar navbar-b navbar-trans navbar-expand-lg"
            id="mainNav"
          >
            <div className="container top-menu">
              <a className="navbar-brand js-scroll" href={"/"}>
                <img src={LogoWhite} className="white-logo" alt="logo" />

                <img src={LogoMobile} className="black-logo" alt="logo" />
              </a>
            </div>
          </nav>
        </header>
        <div className="container">
          <section className="gfda_steps">
            <h2>
              <strong>DMGs and Other Archives: Just Drag and Drop</strong>
            </h2>
            <p>
              Most macOS applications downloaded from outside the store come
              inside a DMG file. Double-click the DMG file to open it, and
              you’ll see a Finder window. Often these will include the
              application itself, some form of arrow, and a shortcut to the
              Applications folder.
            </p>
            <img src={DmgImg} alt="" />
            <p>
              Simply drag the application’s icon to your Applications folder and
              you’re done: the software is now installed. It’s so simple it
              confuses some people—surely there must be more to it than that?
              There isn’t: dragging the application to your Applications folder
              is the entire process.
            </p>
            <p>
              You don’t have to put your programs in the Applications folder,
              though: they’ll run from anywhere. Some people create a “Games”
              directory, to keep games separate from other applications. But
              Applications is the most convenient place to put things, so we
              suggest you just put everything there.
            </p>
            <p>
              DMG files are mounted by your system, like a sort of virtual hard
              drive. When you’re done installing the application, it’s a good
              idea to unmount the DMG in Finder when you’re done installing:
              just click the “Eject” arrow.
            </p>

            <p>
              Then you can feel free to delete the original DMG file: you don’t
              need it anymore.
            </p>
          </section>
          <div>
            <div className="heading">
              <img className="vlogo" src={Logo} alt="" />
              <h3>VRAI Performance</h3>
            </div>
            <p>
              VRAI Performance is one-stop maintenance application for your MAC
              having lovely features like Privacy Cleaner, Junk Cleaner, Trash
              Cleaner, Log Cleaner, Download Manager etc. Activate your VRAI
              Performance using your registred email id to Clean all unwanted
              items from your mac using VRAI Performance to unleash the real
              power of your Mac.
            </p>

            <hr />

            <div className="heading">
              <img className="vlogo" src={DFSIcon} alt="" />
              <h3>VRAI Duplicate File Scanner</h3>
            </div>
            <p>
            VRAI Duplicate File Scanner is a best app to find and remove duplicate files on mac. 
            It works with all kind of duplicate files effortlessly! Activate your VRAI
            Performance using your registred email id to Delete all kind of duplicate files and photos and Recover Storage Space.
            </p>

            <div className="padbot"> <h4>
              <strong >
                Thank You !! <br />
              </strong>
            </h4></div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
