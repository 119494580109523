import React, { Component } from "react";
import Footer from "../Components/Common/Footer";
import Logo from "../Assets/img/install-imgs/logo-512-512.png";
import Security1 from "../Assets/img/install-imgs/securitu-privacy-1.png";
import Security2 from "../Assets/img/install-imgs/securitu-privacy-2.png";
import Security3 from "../Assets/img/install-imgs/securitu-privacy-3.png";
import DFSIcon from "../Assets/img/install-imgs/dfsicon-64x64.png";
import LogoWhite from "../Assets/img/logo.png";
import LogoMobile from "../Assets/img/logo-black.png";

export default class AfterInstall extends Component {
  render() {
    return (
      <div className="install">
        <script src="https://affiliate.cbex.io/affiliate.js"></script>
        <section className="hdrbg_part">
          <h2>Thank you for installing!</h2>
          <img src={Logo} alt="Security Privacy" />
        </section>
        <header className="header-navber-area">
          <nav
            className="navbar navbar-b navbar-trans navbar-expand-lg"
            id="mainNav"
          >
            <div className="container top-menu">
              <a className="navbar-brand js-scroll" href={"/"}>
                <img src={LogoWhite} className="white-logo" alt="logo" />

                <img src={LogoMobile} className="black-logo" alt="logo" />
              </a>
            </div>
          </nav>
        </header>
        <div className="container">
          <section className="gfda_steps">
            <h4>
              {" "}
              <strong>
                Installed application needs to Grant Full Disk Access on your
                Mac OS x 10.15 or later to scan full system.
              </strong>
            </h4>

            <p>
              {" "}
              Installed application to find the all items by granting it full
              access to your disk To allow Full Disk Access, Open your System
              Preferences, authenticate and select Installed application as
              checked in the Full disk Access list.
            </p>

            <h3>Please follow the steps as below to Grant Full Disk Access:</h3>

            <h4>
              <strong>
                Step 1 : Click on the System Preferences from Docker of your
                screen. Open Security and Privacy.
              </strong>
            </h4>

            <img src={Security1} alt="Security Privacy" />

            <h4>
              <strong>
                Step 2 : Click Full Disk Access in the sidebar, then click the
                lock at the bottom left to allow changes. Input your Mac user
                name and password. if required.
              </strong>
            </h4>

            <img src={Security2} alt="Installed application" />

            <h4>
              <strong>
                Step 3 :Select and check the Installed application from list.
                Mac will inform you that System Preferences will not have Full
                Disk Access until it is restarted. Please select Later
              </strong>
            </h4>

            <img src={Security3} alt="Installed application" />
          </section>

          <div className="heading">
            <img className="vlogo" src={Logo} alt="" />
            <h3>VRAI Performance</h3>
          </div>
          <p>
            VRAI Performance is one-stop maintenance application for your MAC
            having lovely features like Privacy Cleaner, Junk Cleaner, Trash
            Cleaner, Log Cleaner, Download Manager etc. Activate your VRAI
            Performance using your registred email id to Clean all unwanted
            items from your mac using VRAI Performance to unleash the real power
            of your Mac.
          </p>

          <hr />

          <div className="heading">
            <img className="vlogo" src={DFSIcon} alt="" />
            <h3>VRAI Duplicate File Scanner</h3>
          </div>
          <p>
            VRAI Duplicate File Scanner is a best app to find and remove duplicate files on mac. 
            It works with all kind of duplicate files effortlessly! Activate your VRAI
            Performance using your registred email id to Delete all kind of duplicate files and photos and Recover Storage Space.
          </p>

          <div className="padbot"> <h4>
              <strong >
                Thank You !! <br />
              </strong>
            </h4></div>
        </div>
        <Footer />
      </div>
    );
  }
}
