import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";

export default class Policy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
          <>
            <Header />
            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}
            <rs-module-wrap id="aboutus_pg">
              <div className="inner_hdr_section">                     
                <h1 className="title">PRIVACY POLICY</h1>                                        
              </div>
            </rs-module-wrap>
            <div className="section-title mt-5 mb-5">
              <div className="container">
             

                <div className="ft-thirty">
                  <p>
                    VRAI Technologies welcomes you to its website and looks
                    forward to a meaningful interaction with you.
                  </p>
                  <p>
                    VRAI Technologies respects your right to privacy. Any
                    personal information that you share with us, like your name,
                    date of birth, address, marital status, telephone number,
                    credit card particulars and the like, shall be entitled to
                    privacy and kept confidential.
                  </p>
                  <p>
                    VRAI Technologies assures you that your personal information
                    shall not be permitted to be used/disclosed, save for the
                    purpose of doing the intended business with you, or if
                    required to be disclosed under the due process of law.
                  </p>
                  <p>
                    VRAI Technologies assures you that in the event of your
                    personal information being shared with its subsidiaries,
                    business associates etc., such sharing of information shall
                    be for the purpose of doing the intended business with you.
                  </p>
                  <p>
                    VRAI Technologies , a company registered in Austin, whose
                    registered address is 10815 Ranch Rd 2222, Building 3B Suite
                    100-527, Austin, TX 78730
                  </p>
                  <p>
                    VRAI Technologies reserves its rights to collect, analyse
                    and disseminate aggregate site usage patterns of all its
                    visitors with a view to enhancing services to its visitors.
                    This includes sharing the information with its subsidiaries,
                    and business associates as a general business practice.
                  </p>
                  <p>
                    In the course of its business VRAI Technologies may hold
                    on-line contests and surveys as permitted by law and it
                    reserves its right to use and disseminate the information so
                    collected to enhance its services to the visitors. This
                    shall also include sharing the information with its
                    subsidiaries and business associates as a general business
                    practice.
                  </p>
                  <p>
                    If you have any questions or concerns regarding your privacy
                    issues, please do not hesitate to contact VRAI Technologies
                    at support@vrai.tech While VRAI
                    Technologies assures you that it will do its best to ensure
                    the privacy and security of your personal information, it
                    shall not be responsible in any manner whatsoever for any
                    violation or misuse of your personal information by
                    unauthorized persons consequent to misuse of the Internet
                    environment.
                  </p>
                  <h2 className="ft-thirty">Cookies :-</h2>
                  <p>
                    To personalize your experience on our website or to support
                    one of our promotions, we may assign your computer browser a
                    unique random number called a cookie. "Cookies" enhance
                    website performance in important ways like personalizing
                    your experience, or making your visit more convenient. Your
                    privacy and security are not compromised when you accept a
                    "cookie" from our website. VRAI Technologies does not use
                    "cookies" to collect personal information. "Cookies" cannot
                    read data from your computer’s hard disk or read cookie
                    files from other websites.
                  </p>
                  <p>
                    VRAI Technologies reserves its rights to revise this privacy
                    policy from time to time at its discretion with a view to
                    making the policy more user friendly.
                  </p>
                  <p>
                    By accessing or using this website, you acquiesce to the
                    privacy policy and agree to be bound by the terms and
                    conditions of the website use.
                  </p>
                  <p>
                    In the design of our website, we have taken care to draw
                    your attention to this privacy policy so that you are aware
                    of the terms under which you may decide to share your
                    personal information with us. Accordingly, should you choose
                    to share your personal information with us, VRAI
                    Technologies will assume that you have no objections to the
                    terms of this privacy policy.
                  </p>
                  <h2 className="ft-thirty">Conditions of Use &amp; Notices</h2>

                  <p>
                    If you choose to visit the VRAI Technologies website, your
                    visit and any dispute over privacy is subject to this Notice
                    and application of the law of the Union of Austin. Any
                    dispute arising out of or in relation to the use of this
                    website shall be subject to the sole jurisdiction of the
                    courts at jaipur.
                  </p>
                </div>
              </div>
            </div>
            <div className="pvcpol_pg"><Footer /></div>
          </>
      </>
    );
  }
}
