import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import Aboutus from "../Components/AboutUs";
import Testimonials from "../Components/Testimonals";

export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
           <div>
           <div id="Header_wrapper">
              <header id="Header">
            <Header />

            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}

            <rs-module-wrap id="aboutus_pg">
                    <div className="inner_hdr_section">                     
                      <h1 className="title">ABOUT US</h1>                                        
                    </div>
                  </rs-module-wrap>
                  </header>
            <Aboutus />
            </div> 
            <Testimonials/>
            <Footer />
            </div>
      </>
    );
  }
}
