
const InputField = (props) => {
  const { handleChange, label, name, type, value, placeholder } = props;
  return (
    <>
     
      <input className="form-text form-control fcf-form-control" type={type} onChange={handleChange} value={value} name={name} placeholder={placeholder} required />
      </>
 
  )
}

export default InputField