import React, { Component } from "react";
import WhatWeProvide from "../Components/WhatWeProvide";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
          <>
            <Header />
            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}
            <rs-module-wrap id="aboutus_pg">
                    <div className="inner_hdr_section">                     
                      <h1 className="title">SERVICES</h1>                                        
                    </div>
                  </rs-module-wrap>
            

            <section
              id="review"
              className="testimonial-area sec-services section-padding text-center"
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title">
                      <h2 className="whiteclr">INSTALL VRAI TODAY</h2>
                      <span className="style2">AND GAIN PEACE OF MIND</span>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-5">
                    <span
                      id="hs_cos_wrapper_module_1612074849831415_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="text"
                    >
                      <svg
                        className="shapeclr"
                        xmlns="http://www.w3.org/2000/svg"
                        width="500"
                        height="500"
                        viewBox="0 0 500 500"
                      >
                        {" "}
                        <title>_</title>{" "}
                        <g>
                          {" "}
                          <path d="M387.26,315.91H112.74V143.2H387.26Zm-266.52-8H379.26V151.2H120.74Z"></path>{" "}
                          <path d="M386,356.8H114a28.522,28.522,0,0,1-28.49-28.49v-20.4H209.33v4a9.972,9.972,0,0,0,9.96,9.96h61.42a9.972,9.972,0,0,0,9.96-9.96v-4H414.49v20.4A28.522,28.522,0,0,1,386,356.8ZM93.51,315.91v12.4A20.513,20.513,0,0,0,114,348.8H386a20.513,20.513,0,0,0,20.49-20.49v-12.4H298.221a17.991,17.991,0,0,1-17.511,13.96H219.29a17.991,17.991,0,0,1-17.511-13.96Z"></path>{" "}
                        </g>{" "}
                        <path d="M198.333,223H149V173.667h49.333ZM157,215h33.333V181.667H157Z"></path>{" "}
                        <polygon points="174 208.541 161.892 196.881 167.441 191.119 173.333 196.792 191.25 175.118 197.417 180.215 174 208.541"></polygon>{" "}
                        <path d="M198.333,287.6H149V238.265h49.333ZM157,279.6h33.333V246.265H157Z"></path>{" "}
                        <polygon points="174 273.139 161.892 261.479 167.441 255.716 173.333 261.39 191.25 239.716 197.417 244.813 174 273.139"></polygon>{" "}
                        <rect
                          x="230"
                          y="173.667"
                          width="121.5"
                          height="8"
                        ></rect>{" "}
                        <rect
                          x="230"
                          y="194.333"
                          width="121.5"
                          height="8"
                        ></rect>{" "}
                        <rect x="230" y="215" width="121.5" height="8"></rect>{" "}
                        <rect
                          x="230"
                          y="235.667"
                          width="121.5"
                          height="8"
                        ></rect>{" "}
                        <rect
                          x="230"
                          y="256.333"
                          width="121.5"
                          height="8"
                        ></rect>{" "}
                        <rect x="230" y="277" width="121.5" height="8"></rect>{" "}
                      </svg>
                    </span>

                    <h3> 24/7 VULNERABILITY MANAGEMENT</h3>
                    <p>
                      Our remote VRAI is armed with experience to improve
                      performance, fill in your gaps and reduce expenses.
                    </p>
                  </div>

                  <div className="col-md-4 mb-5">
                    <span
                      id="hs_cos_wrapper_module_1612074849831415_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="text"
                    >
                      <svg
                        className="shapeclr"
                        xmlns="http://www.w3.org/2000/svg"
                        width="500"
                        height="500"
                        viewBox="0 0 500 500"
                      >
                        {" "}
                        <title>_</title>{" "}
                        <g>
                          {" "}
                          <path d="M250.038,296.684a41.68,41.68,0,1,1,39.278-55.511h0a41.685,41.685,0,0,1-39.278,55.511Zm-.012-75.376a33.689,33.689,0,1,0,31.744,22.52h0a33.741,33.741,0,0,0-31.744-22.52Z"></path>{" "}
                          <path d="M261.06,336.8l-6.654-18.915a62.308,62.308,0,0,1-15.616-.857l-8.673,18.085L199.98,320.664l8.673-18.086a62.414,62.414,0,0,1-10.445-11.64l-18.915,6.654L168.2,266.063l18.915-6.654a62.4,62.4,0,0,1,.857-15.616l-18.086-8.673,14.452-30.137,18.085,8.673a62.443,62.443,0,0,1,11.64-10.445L207.412,184.3l31.528-11.091,6.654,18.915a62.374,62.374,0,0,1,15.616.857l8.673-18.085,30.137,14.451-8.673,18.086a62.413,62.413,0,0,1,10.445,11.64l18.915-6.653L331.8,243.944,312.883,250.6a62.422,62.422,0,0,1-.857,15.616l18.086,8.673L315.66,305.023l-18.085-8.673a62.427,62.427,0,0,1-11.64,10.445l6.654,18.915Zm-1.214-27.559,6.106,17.358,16.436-5.782-6.106-17.357,2.735-1.693a54.554,54.554,0,0,0,14.312-12.842l1.98-2.535,16.6,7.959,7.533-15.709-16.6-7.959.737-3.132a54.562,54.562,0,0,0,1.053-19.2l-.392-3.193,17.358-6.106-5.781-16.436-17.358,6.105-1.693-2.735a54.546,54.546,0,0,0-12.841-14.31l-2.536-1.981,7.959-16.6-15.71-7.533-7.959,16.6-3.132-.737a54.538,54.538,0,0,0-19.2-1.053l-3.193.392-6.106-17.358-16.436,5.782,6.106,17.357-2.735,1.693a54.563,54.563,0,0,0-14.311,12.841l-1.98,2.535-16.6-7.959-7.533,15.71,16.6,7.959-.737,3.132a54.553,54.553,0,0,0-1.053,19.2l.392,3.193L178.4,270.954l5.781,16.436,17.358-6.106,1.693,2.735a54.548,54.548,0,0,0,12.841,14.311l2.536,1.981-7.959,16.6,15.71,7.533,7.959-16.6,3.131.737a54.538,54.538,0,0,0,19.2,1.053Z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <circle
                            cx="265.115"
                            cy="113.838"
                            r="15.135"
                            transform="translate(-0.372 226.804) rotate(-46.288)"
                          ></circle>{" "}
                          <path d="M111.349,236.9l-7.917-1.146A148.38,148.38,0,0,1,219.347,112.246l1.646,7.829A140.352,140.352,0,0,0,111.349,236.9Z"></path>{" "}
                          <path d="M388.652,236.9A140.4,140.4,0,0,0,279.23,120.123a137.76,137.76,0,0,0-14.536-2.3l.832-7.957A145.936,145.936,0,0,1,280.9,112.3,148.424,148.424,0,0,1,396.569,235.757Z"></path>{" "}
                          <path d="M229.111,403.831A148.174,148.174,0,0,1,102.219,267.269l7.982-.537c4.417,65.655,54.9,119.982,120.028,129.178Z"></path>{" "}
                          <path d="M270.89,403.831l-1.118-7.922c65.125-9.195,115.6-63.522,120.018-129.178l7.982.537A148.165,148.165,0,0,1,270.89,403.831Z"></path>{" "}
                        </g>{" "}
                        <path d="M130.218,271H81.552V232.333h48.667Zm-40.667-8h32.667V240.333H89.552Z"></path>{" "}
                        <path d="M418.448,271H369.782V232.333h48.667Zm-40.667-8h32.667V240.333H377.782Z"></path>{" "}
                        <path d="M274.334,420.631H225.667V381.964h48.667Zm-40.667-8h32.667V389.964H233.667Z"></path>{" "}
                      </svg>
                    </span>
                    <h3>24/7 DIGITAL ASSET MANAGEMENT</h3>
                    <p>
                      Our Asset Management feature will help us manage your
                      complex technology challenges for you. We automatically
                      discover, monitor and track the details so you don't have
                      to.
                    </p>
                  </div>

                  <div className="col-md-4 mb-5">
                    <span
                      id="hs_cos_wrapper_module_1612074849831415_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="text"
                    >
                      <svg
                        className="shapeclr"
                        xmlns="http://www.w3.org/2000/svg"
                        width="500"
                        height="500"
                        viewBox="0 0 500 500"
                      >
                        {" "}
                        <title>_</title>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M351.9,351.9H148.1V148.1H351.9Zm-195.8-8H343.9V156.1H156.1Z"></path>{" "}
                            <rect
                              x="121.433"
                              y="162"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="186"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="210"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="234"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="258"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="282"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="306"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="330"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="162"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="186"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="210"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="234"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="258"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="282"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="306"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="330"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="330"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="306"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="282"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="258"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="234"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="210"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="186"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="162"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="330"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="306"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="282"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="258"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="234"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="210"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="186"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="162"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                          </g>{" "}
                          <path d="M326.083,326.083H173.917V173.917H326.083Zm-144.167-8H318.083V181.917H181.917Z"></path>{" "}
                          <g>
                            {" "}
                            <polygon points="201.917 214 193.917 214 193.917 193.667 216.583 193.667 216.583 201.667 201.917 201.667 201.917 214"></polygon>{" "}
                            <polygon points="306.083 215.167 298.083 215.167 298.083 200.5 285.75 200.5 285.75 192.5 306.083 192.5 306.083 215.167"></polygon>{" "}
                          </g>{" "}
                          <g>
                            {" "}
                            <polygon points="306.083 307.5 283.417 307.5 283.417 299.5 298.083 299.5 298.083 287.167 306.083 287.167 306.083 307.5"></polygon>{" "}
                            <polygon points="214.25 308.667 193.917 308.667 193.917 286 201.917 286 201.917 300.667 214.25 300.667 214.25 308.667"></polygon>{" "}
                          </g>{" "}
                        </g>{" "}
                      </svg>
                    </span>
                    <h3>24/7 SOFTWARE PATCH MANAGEMENT</h3>
                    <p>
                      Your data is out on the world wide web and the hidden web.
                      Let's change that. Proactively remove your data from the
                      Open and Dark Web.
                    </p>
                  </div>

                  <div className="col-md-4">
                    <span
                      id="hs_cos_wrapper_module_1612074849831415_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="text"
                    >
                      <svg
                        className="shapeclr"
                        xmlns="http://www.w3.org/2000/svg"
                        width="500"
                        height="500"
                        viewBox="0 0 500 500"
                      >
                        {" "}
                        <title>_</title>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M351.9,351.9H148.1V148.1H351.9Zm-195.8-8H343.9V156.1H156.1Z"></path>{" "}
                            <rect
                              x="121.433"
                              y="162"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="186"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="210"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="234"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="258"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="282"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="306"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="121.433"
                              y="330"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="162"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="186"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="210"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="234"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="258"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="282"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="306"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="347.9"
                              y="330"
                              width="30.667"
                              height="8"
                            ></rect>{" "}
                            <rect
                              x="330"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="306"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="282"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="258"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="234"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="210"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="186"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="162"
                              y="347.9"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="330"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="306"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="282"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="258"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="234"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="210"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="186"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                            <rect
                              x="162"
                              y="121.433"
                              width="8"
                              height="30.667"
                            ></rect>{" "}
                          </g>{" "}
                          <path d="M326.083,326.083H173.917V173.917H326.083Zm-144.167-8H318.083V181.917H181.917Z"></path>{" "}
                          <g>
                            {" "}
                            <polygon points="201.917 214 193.917 214 193.917 193.667 216.583 193.667 216.583 201.667 201.917 201.667 201.917 214"></polygon>{" "}
                            <polygon points="306.083 215.167 298.083 215.167 298.083 200.5 285.75 200.5 285.75 192.5 306.083 192.5 306.083 215.167"></polygon>{" "}
                          </g>{" "}
                          <g>
                            {" "}
                            <polygon points="306.083 307.5 283.417 307.5 283.417 299.5 298.083 299.5 298.083 287.167 306.083 287.167 306.083 307.5"></polygon>{" "}
                            <polygon points="214.25 308.667 193.917 308.667 193.917 286 201.917 286 201.917 300.667 214.25 300.667 214.25 308.667"></polygon>{" "}
                          </g>{" "}
                        </g>{" "}
                      </svg>
                    </span>
                    <h3> 24/7 CONFIGURATION MANAGEMENT</h3>
                    <p>
                      Outdated or faulty software becomes GapWare and that paves
                      the way for hackers to get in. Let us patch the gaps
                      regularly for you. We do it for you. Sleep well at night.
                    </p>
                  </div>

                  <div className="col-md-4">
                    <span
                      id="hs_cos_wrapper_module_1612074849831415_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="text"
                    >
                      <svg
                        className="shapeclr"
                        xmlns="http://www.w3.org/2000/svg"
                        width="500"
                        height="500"
                        viewBox="0 0 500 500"
                      >
                        {" "}
                        <title>_</title>{" "}
                        <g>
                          {" "}
                          <g>
                            {" "}
                            <path d="M250.018,266.453a19.116,19.116,0,1,1,18.014-25.459h0a19.117,19.117,0,0,1-18.014,25.459Zm-.029-30.232a11.116,11.116,0,1,0,10.5,7.428h0A11.135,11.135,0,0,0,249.989,236.22Z"></path>{" "}
                            <path d="M255.524,297.7l-4.062-11.549a38.557,38.557,0,0,1-7.13-.392l-5.3,11.042-20.741-9.946,5.295-11.041a38.559,38.559,0,0,1-4.77-5.315l-11.548,4.063-7.633-21.7,11.549-4.062a38.463,38.463,0,0,1,.392-7.13l-11.042-5.295,9.946-20.742,11.042,5.3a38.474,38.474,0,0,1,5.315-4.77l-4.062-11.549,21.7-7.633,4.063,11.549a38.557,38.557,0,0,1,7.13.392l5.3-11.042,20.74,9.946-5.294,11.042a38.47,38.47,0,0,1,4.77,5.314l11.548-4.062,7.633,21.7-11.549,4.063a38.463,38.463,0,0,1-.392,7.13l11.042,5.3-9.946,20.741-11.042-5.3a38.474,38.474,0,0,1-5.315,4.77l4.063,11.549Zm1.4-20.142,3.5,9.94,6.606-2.324-3.5-9.939,2.735-1.694a30.531,30.531,0,0,0,8.016-7.193l1.981-2.535,9.506,4.559,3.027-6.313-9.505-4.559.737-3.132a30.552,30.552,0,0,0,.59-10.754l-.392-3.193,9.94-3.5-2.324-6.605-9.94,3.5-1.693-2.735a30.547,30.547,0,0,0-7.193-8.016l-2.536-1.98,4.559-9.507-6.314-3.028-4.558,9.507-3.132-.737a30.6,30.6,0,0,0-10.754-.591l-3.193.393-3.5-9.94-6.606,2.324,3.5,9.939-2.735,1.694a30.531,30.531,0,0,0-8.016,7.193l-1.981,2.535-9.506-4.559-3.027,6.314,9.506,4.558-.737,3.132a30.552,30.552,0,0,0-.59,10.754l.392,3.193-9.94,3.5,2.324,6.605,9.94-3.5L223.8,263.6a30.579,30.579,0,0,0,7.193,8.018l2.535,1.98-4.558,9.5,6.314,3.028,4.558-9.507,3.132.737a30.536,30.536,0,0,0,10.754.591Z"></path>{" "}
                          </g>{" "}
                          <g>
                            {" "}
                            <circle
                              cx="265.115"
                              cy="106.171"
                              r="15.135"
                              transform="translate(5.169 224.435) rotate(-46.288)"
                            ></circle>{" "}
                            <path d="M111.349,229.237l-7.917-1.146A148.38,148.38,0,0,1,219.347,104.579l1.646,7.829A140.352,140.352,0,0,0,111.349,229.237Z"></path>{" "}
                            <path d="M388.652,229.237A140.4,140.4,0,0,0,279.23,112.457a137.5,137.5,0,0,0-14.536-2.3l.831-7.957a145.575,145.575,0,0,1,15.375,2.438A148.422,148.422,0,0,1,396.569,228.09Z"></path>{" "}
                            <path d="M229.111,396.165A148.175,148.175,0,0,1,102.219,259.6l7.982-.537c4.417,65.654,54.9,119.981,120.028,129.178Z"></path>{" "}
                            <path d="M270.89,396.165l-1.118-7.922c65.125-9.2,115.6-63.522,120.018-129.178l7.982.537A148.165,148.165,0,0,1,270.89,396.165Z"></path>{" "}
                          </g>{" "}
                          <path d="M130.218,263.333H81.552V224.666h48.667Zm-40.667-8h32.667V232.666H89.552Z"></path>{" "}
                          <path d="M418.448,263.333H369.782V224.666h48.667Zm-40.667-8h32.667V232.666H377.782Z"></path>{" "}
                          <path d="M274.333,412.964H225.667V374.3h48.667Zm-40.667-8h32.667V382.3H233.667Z"></path>{" "}
                          <path d="M249.979,359.6l-1.943-1.08c-52.429-29.139-73.1-74.337-81.2-107.123-8.756-35.414-5.208-63.594-5.052-64.776l.367-2.793,2.753-.6c56.958-12.323,82.061-35.958,82.307-36.195l2.772-2.664,2.771,2.664c.244.232,25.59,23.924,82.308,36.195l2.753.6.367,2.793c.155,1.182,3.7,29.362-5.053,64.776-8.106,32.786-28.775,77.984-81.2,107.123Zm-80.536-169.18a192.209,192.209,0,0,0,5.225,59.338c11.094,44.568,36.427,78.428,75.311,100.674,38.972-22.295,64.327-56.248,75.381-100.955a191.619,191.619,0,0,0,5.158-59.057c-47.212-10.7-72.558-28.7-80.539-35.21C242,161.717,216.649,179.72,169.444,190.421Z"></path>{" "}
                        </g>{" "}
                      </svg>
                    </span>
                    <h3>24/7 DEVICE SECURITY</h3>
                    <p>
                      DeepArmor technology inoculates your device for Virus
                      immunity. Get ahead of all indications of attacks and
                      compromise! We will stop threats before they happen.
                    </p>
                  </div>

                  <div className="col-md-4">
                    <span
                      id="hs_cos_wrapper_module_1612074849831415_"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="text"
                    >
                      <svg
                        className="shapeclr"
                        xmlns="http://www.w3.org/2000/svg"
                        width="500"
                        height="500"
                        viewBox="0 0 500 500"
                      >
                        {" "}
                        <title>_</title>{" "}
                        <g>
                          {" "}
                          <path d="M250.038,296.684a41.68,41.68,0,1,1,39.278-55.511h0a41.685,41.685,0,0,1-39.278,55.511Zm-.012-75.376a33.689,33.689,0,1,0,31.744,22.52h0a33.741,33.741,0,0,0-31.744-22.52Z"></path>{" "}
                          <path d="M261.06,336.8l-6.654-18.915a62.308,62.308,0,0,1-15.616-.857l-8.673,18.085L199.98,320.664l8.673-18.086a62.414,62.414,0,0,1-10.445-11.64l-18.915,6.654L168.2,266.063l18.915-6.654a62.4,62.4,0,0,1,.857-15.616l-18.086-8.673,14.452-30.137,18.085,8.673a62.443,62.443,0,0,1,11.64-10.445L207.412,184.3l31.528-11.091,6.654,18.915a62.374,62.374,0,0,1,15.616.857l8.673-18.085,30.137,14.451-8.673,18.086a62.413,62.413,0,0,1,10.445,11.64l18.915-6.653L331.8,243.944,312.883,250.6a62.422,62.422,0,0,1-.857,15.616l18.086,8.673L315.66,305.023l-18.085-8.673a62.427,62.427,0,0,1-11.64,10.445l6.654,18.915Zm-1.214-27.559,6.106,17.358,16.436-5.782-6.106-17.357,2.735-1.693a54.554,54.554,0,0,0,14.312-12.842l1.98-2.535,16.6,7.959,7.533-15.709-16.6-7.959.737-3.132a54.562,54.562,0,0,0,1.053-19.2l-.392-3.193,17.358-6.106-5.781-16.436-17.358,6.105-1.693-2.735a54.546,54.546,0,0,0-12.841-14.31l-2.536-1.981,7.959-16.6-15.71-7.533-7.959,16.6-3.132-.737a54.538,54.538,0,0,0-19.2-1.053l-3.193.392-6.106-17.358-16.436,5.782,6.106,17.357-2.735,1.693a54.563,54.563,0,0,0-14.311,12.841l-1.98,2.535-16.6-7.959-7.533,15.71,16.6,7.959-.737,3.132a54.553,54.553,0,0,0-1.053,19.2l.392,3.193L178.4,270.954l5.781,16.436,17.358-6.106,1.693,2.735a54.548,54.548,0,0,0,12.841,14.311l2.536,1.981-7.959,16.6,15.71,7.533,7.959-16.6,3.131.737a54.538,54.538,0,0,0,19.2,1.053Z"></path>{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <circle
                            cx="265.115"
                            cy="113.838"
                            r="15.135"
                            transform="translate(-0.372 226.804) rotate(-46.288)"
                          ></circle>{" "}
                          <path d="M111.349,236.9l-7.917-1.146A148.38,148.38,0,0,1,219.347,112.246l1.646,7.829A140.352,140.352,0,0,0,111.349,236.9Z"></path>{" "}
                          <path d="M388.652,236.9A140.4,140.4,0,0,0,279.23,120.123a137.76,137.76,0,0,0-14.536-2.3l.832-7.957A145.936,145.936,0,0,1,280.9,112.3,148.424,148.424,0,0,1,396.569,235.757Z"></path>{" "}
                          <path d="M229.111,403.831A148.174,148.174,0,0,1,102.219,267.269l7.982-.537c4.417,65.655,54.9,119.982,120.028,129.178Z"></path>{" "}
                          <path d="M270.89,403.831l-1.118-7.922c65.125-9.195,115.6-63.522,120.018-129.178l7.982.537A148.165,148.165,0,0,1,270.89,403.831Z"></path>{" "}
                        </g>{" "}
                        <path d="M130.218,271H81.552V232.333h48.667Zm-40.667-8h32.667V240.333H89.552Z"></path>{" "}
                        <path d="M418.448,271H369.782V232.333h48.667Zm-40.667-8h32.667V240.333H377.782Z"></path>{" "}
                        <path d="M274.334,420.631H225.667V381.964h48.667Zm-40.667-8h32.667V389.964H233.667Z"></path>{" "}
                      </svg>
                    </span>
                    <h3>24/7 REMOTE ASSET MANAGEMENT</h3>
                    <p>
                      Let our team remotely monitor you 24/7 and prevent issues
                      from happening. We handle the tech stuff seamlessly,
                      providing swift and secured device management. We do it
                      for you!
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <WhatWeProvide title="IT Solutions" />
            <Footer />
          </>
      </>
    );
  }
}
