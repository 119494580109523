

import React, { Component } from "react";

import Award1 from "../Assets/img/vrai/awards/platinum-award-winner-1.png";
import Award2 from "../Assets/img/vrai/awards/platinum-award-winner-2.png";
import Award3 from "../Assets/img/vrai/awards/platinum-award-winner-3.png";
import Award4 from "../Assets/img/vrai/awards/cyber-security-award.png";
import Award5 from "../Assets/img/vrai/awards/tech-starter.png";
import Award6 from "../Assets/img/vrai/awards/tnt-awards.png";
import Award7 from "../Assets/img/vrai/awards/cedia-propel.png";

export default class Award extends Component {
 
  render() {
    return (
      <>
        <div class="section mcb-section mcb-section-2vqahjiyg itsolsecawd">
                        <div class="section_wrapper mcb-section-inner section_nwsize">


                          <div class="wrap mcb-wrap one-fourth  valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award1} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wrap mcb-wrap one-fourth valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award2} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wrap mcb-wrap  one-fourth  valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award3} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wrap mcb-wrap  one-fourth  valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award4} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wrap mcb-wrap  one-fourth  valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award5} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wrap mcb-wrap  one-fourth  valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award6} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="wrap mcb-wrap  one-fourth  valign-top clearfix">
                            <div class="mcb-wrap-inner">
                              <div class="column mcb-column one column_column">
                                <div class="column_attr clearfix align_center">
                                  <div class="image_frame image_item no_link scale-with-grid no_border">
                                    <div class="image_wrapper"><img src={Award7} alt="VRAI Award"/></div>
                                  </div>                                  
                                </div>
                              </div>
                            </div>
                          </div>

                          </div>

                          {/* <div class="section_wrapper mcb-section-inner section_nwsize awardsec">

                          

                        </div> */}


                      </div>

      </>
    );
  }
}
