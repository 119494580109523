import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";

export default class UpcomingEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
        <Header />
        {/* <header className="pages-banner">
          <div className="container">
            <h1>Upcoming Events</h1>
          </div>
        </header> */}
        

        <rs-module-wrap id="aboutus_pg">
          <div className="inner_hdr_section">                     
            <h1 className="title">UPCOMING EVENTS</h1>                                        
          </div>
        </rs-module-wrap>

        <div className="section-title mt-5 mb-0 pt-5 pb-5 lightbg">
          <div className="container">
            <h2 className="mb-0">Coming Soon...</h2>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
