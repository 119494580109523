import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import * as emailjs from "emailjs-com";
import ContactsForm from "../Components/ContactUsForm";
import call from "../Assets/img/vrai/data-contact-icon1.png";
import address from "../Assets/img/vrai/data-contact-icon3.png";
import email from "../Assets/img/vrai/data-contact-icon2.png";
import icon1 from "../Assets/img/vrai/data-icon1.png";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
      loading: true,
    };
  }
  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value });
  };

  handleSubmit(e) {
    e.preventDefault();
    const { firstName, lastName, email, message } = this.state;
    let templateParams = {
      from_name: email,
      to_name: "aaditya@crowdpointtech.com",
      user_name: firstName + " " + lastName,
      message: message,
    };
    emailjs.send(
      "service_w1w6f1s",
      "template_odq49vr",
      templateParams,
      "a1170giFZgBo8V2Xg"
    );
    this.resetForm();
  }
  resetForm() {
    this.setState({
      firstName: "",
      email: "",
      lastName: "",
      message: "",
    });
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
          <>
            <Header />
            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}

          <rs-module-wrap id="aboutus_pg">
              <div className="inner_hdr_section">                     
                <h1 className="title">CONTACT US</h1>                                        
              </div>
            </rs-module-wrap>

            <section id="contact" className="contact-area section-padding">
              <div className="container">

              

                
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title cont_desc">


                    <div class="column_attr clearfix align_center">
                      <div class="image_frame image_item no_link scale-with-grid alignnone no_border">
                        <div class="image_wrapper">
                      <img class="scale-with-grid" src={icon1} alt="data-icon1"/>
                      </div>
                      </div>
                     <hr class="no_line" style={{margin: '0 auto 5px auto'}} />  
                      <h6>CONTACT US</h6>
                      <h2 className="contactustitle">LEAVE A MESSAGE</h2>
                      </div>



                     {/*  <h5>24/7</h5>
                      <h2 className="contactustitle">Contact Us</h2> */}
                      <p>
                        We look forward to hearing from you and reaching out to
                        us. We want to hear from you. We have provided you with
                        a number of ways to communicate with us. Feel free to
                        use the information or form below.
                      </p>
                    </div>
                  </div>
                </div>
              
                
                <div className="row">
                  <div className="col-lg-12 col-md-12 queryformsection">
                    <div className="row">
                      <div className="col-lg-12 col-md-12 queryformsection">
                      <ContactsForm/>
                      
                        {/* <form
                          id="fcf-form-id"
                          className="fcf-form-class contactform"
                          onSubmit={this.handleSubmit.bind(this)}
                        >
                          <div className="fcf-form-group field formfield">
                            <input
                              type="text"
                              id="Name"
                              name="firstName"
                              className="fcf-form-control"
                              value={this.state.firstName}
                              onChange={this.handleChange.bind(
                                this,
                                "firstName"
                              )}
                              placeholder="Enter Your First Name"
                              required=""
                            />
                            <input
                              type="text"
                              id="Lname"
                              name="lastName"
                              value={this.state.lastName}
                              onChange={this.handleChange.bind(
                                this,
                                "lastName"
                              )}
                              className="fcf-form-control"
                              placeholder="Enter Your Last Name"
                              required=""
                            />
                            <input
                              type="email"
                              id="Email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange.bind(this, "email")}
                              className="fcf-form-control email"
                              placeholder="Enter Your Email Address"
                              required=""
                            />
                          </div>

                          <div className="fcf-form-group field formmessage">
                            <textarea
                              id="Message"
                              name="message"
                              className="fcf-form-control"
                              value={this.state.message}
                              onChange={this.handleChange.bind(this, "message")}
                              rows="6"
                              maxlength="3000"
                              placeholder="Enter Your Message"
                              required=""
                            ></textarea>
                          </div>

                          <div className="btn_sec">
                            <button
                              type="submit"
                              id="fcf-button"
                              className="fcf-btn fcf-btn-primary fcf-btn-lg fcf-btn-block submit_btn"
                            >
                              Submit Now
                            </button>
                          </div>
                        </form> */}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


            <section id="contact" className="contact-area lightbg section-padding">
              <div className="container">
            <div className="row mt-5">
                  <div className="col-lg-4 col-md-4">
                    <div className="contact-store-location-box">
                      {/* <i className="fa fa-phone"></i> */}
                      {/* <img src={call}/> */}
                      <div class="image_frame image_item no_link scale-with-grid no_border"><div class="image_wrapper"><img src={call} alt="call"/></div></div>
                      <div className="single-store-location">
                        <h3>CALL US</h3>

                        <p>
                          {" "}
                          <a href="tel:+15127671773"> +1 (512) 767-1773</a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="contact-store-location-box">
                      {/* <i className="fa fa-map"></i> */}                     
                      <div class="image_frame image_item no_link scale-with-grid no_border"><div class="image_wrapper"><img src={address} alt="address"/></div></div>
                      <div className="single-store-location">
                        <h3>ADDRESS</h3>
                        <p>
                          10815 Ranch Rd 2222, Building 3B Suite 100-527,
                          Austin, TX 78730.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="contact-store-location-box">
                      {/* <i className="fa fa-envelope"></i> */}                    
                      <div class="image_frame image_item no_link scale-with-grid no_border">
                        <div class="image_wrapper">
                        <img src={email} alt="email"/>
                        </div>
                        </div>
                      <div className="single-store-location">
                        <h3>EMAIL</h3>
                        <p>                        
                          <a className="email" href="mailto:support@vrai.tech">                            
                          support@vrai.tech
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                </div>
            </section>
            <Footer />
          </>
    );
  }
}
