import React, { Component } from "react";
import lightlogo from "../../Assets/img/vrai/light-logo.png";
import darklogo from "../../Assets/img/vrai/dark-logo.png";

export default class header extends Component {

 
    // constructor(props) {
    //   super(props)
    //   this.state = { isToggleOn: false }
  
    //   this.handleClick = this.handleClick.bind(this)
    // }
  
  
    // handleClick() {
    //   this.setState((prevState) => ({
    //     isToggleOn: !prevState.isToggleOn
    //   }))
    // }

  render() {
    return (
      <>

<header class="header-navber-area" id="Top_bar">
          <nav
            class="navbar navbar-b navbar-trans navbar-expand-lg"
            id="mainNav"
          >
            <div class="container top-menu">
              <a class="navbar-brand js-scroll" href={"/"}>
                <img src={lightlogo} class="white-logo" alt="logo" />

                <img src={darklogo} class="black-logo" alt="logo" />
              </a>

              <button
                class="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarDefault"
                aria-controls="navbarDefault"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {" "}
                <span></span> <span></span> <span></span>
              </button>

              <div
                class="navbar-collapse collapse justify-content-end"
                id="navbarDefault"
              >
                <ul class="navbar-nav">
                  <li class="nav-item">
                    <a class="nav-link js-scroll active" href={"/"}>
                      Home
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll" href="/about-us">
                      About Us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll" href={"/services"}>
                      Services
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll" href={"/pricing"}>
                      Plan
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                      class="nav-link js-scroll"
                      href="#!"
                    >
                      Events
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link js-scroll" href={"/contact-us"}>
                      contact
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeLogin"
                      class="nav-link js-scroll"
                    >
                      Login
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#!"
                      data-bs-toggle="modal"
                      data-bs-target="#iframeCart"
                      class="nav-link js-scroll"
                    >
                      Cart
                    </a>
                  </li>
                </ul>

                
              </div>
            </div>
          </nav>
 
        </header>
     



{/* Event Modal */}
          <div
          class="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modalwd modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body mainsec">
                <a class="btn upcoming btn-primary" href={"/upcoming-events"}>
                  Upcoming Events
                </a>
                <a class="btn past btn-primary" href={"/events"}>
                  Past Events
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* Event Modal */}

{/* Login Modal */}
        <div
          class="modal fade"
          id="iframeLogin"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe
                  src="https://10xcyber.market/orders?isEmbedded=1"
                  width="1000"
                  height="500"
                  title={"map"}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

{/* Cart Modal */}
        <div
          class="modal fade"
          id="iframeCart"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe
                  src="https://10xcyber.market/cart?isEmbedded=1"
                  width="1000"
                  height="500"
                  title={"map"}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

{/* Product Modal */}



<div
          class="modal fade"
          id="iframeModel1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div class="modal-content">
              <div>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe
                  src="https://10xcyber.market/product/2021-12-10-ITX-CPM-vrai-001?isEmbedded=1"
                  width="1000"
                  height="500"
                  title={"map"}
                ></iframe>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
