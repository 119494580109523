const TextareaField = (props) => {
  const { handleChange, label, name, value, placeholder } = props;
  return (
    <div className="form-group form-item webform-component-textarea">
      <label className="text-gray-500 text-sm" htmlFor={name}>{label}</label>
      <textarea onChange={handleChange} name={name} rows="4" className="form-control form-textarea" value={value} placeholder={placeholder}></textarea>
    </div>
  )
}

export default TextareaField;