import React from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Button } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Testmonial1 from "../Assets/img/testimonials/tmonial1.jpg";
import Testmonial2 from "../Assets/img/testimonials/tmonial2.jpg";
import Testmonial3 from "../Assets/img/testimonials/tmonial3.jpg";
import Testmonial4 from "../Assets/img/testimonials/tmonial4.jpg";
import Testmonial5 from "../Assets/img/testimonials/tmonial5.jpg";
import Testmonial6 from "../Assets/img/testimonials/tmonial6.jpg";
import Testmonial7 from "../Assets/img/testimonials/tmonial7.jpg";
import Testmonial8 from "../Assets/img/testimonials/tmonial8.jpg";
import Testmonial9 from "../Assets/img/testimonials/tmonial9.jpg";
import Testmonial11 from "../Assets/img/testimonials/tmonial11.jpg";
import Testmonial12 from "../Assets/img/testimonials/tmonial12.jpg";
import Testmonial13 from "../Assets/img/testimonials/tmonial13.jpg";

import icon1 from "../Assets/img/vrai/data-icon1.png";

export default function Testimonials() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    console.log(data.target.children[0].getAttribute("data"));
    setData(data.target.children[0].getAttribute("data"));
    setShow(true);
  };

  return (
    <>
      <div className="section mcb-section mcb-section-vf7be9gz7" style={{paddingTop: '30px', paddingBottom: '70px', backgroundColor: '#ffffff'}}>
        <div className="section_wrapper mcb-section-inner">
          <div className="wrap mcb-wrap mcb-wrap-zcvrtmdr7 one  valign-top clearfix" style={{}}>
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-1ani18nto one column_divider">
                <hr className="no_line" style={{margin: '0 auto 50px auto'}} />
              </div>
              <div className="column mcb-column mcb-item-4h59dm5ri one column_column column-margin-10px">
                <div className="column_attr clearfix align_center" style={{}}>
                  <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={icon1} alt="data-icon1" title width={15} height={16} /></div>
                  </div>
                  <hr className="no_line" style={{margin: '0 auto 5px auto'}} />
                  <h6>TEAM</h6>
                  <h2 style={{color: '#0d0f39'}}>TESTIMONIALS</h2>
                </div>
              </div>
              <div className="column mcb-column mcb-item-e36btmcxa one column_divider">
                <hr className="no_line" style={{margin: '0 auto 30px auto'}} />
              </div>
            </div>
          </div>

         <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>VRAI Videos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <video><source src={data}></source></video> */}
          <iframe
            src={data}
            frameborder="0"
            allow="autoplay; encrypted-media"
            width="750"
            height="400"
            title="video"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            X
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={1200}
      >
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560720?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1"
              src={Testmonial7}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              VRAI detected quite a few issues immediately and then it was a
              simple wipe of the issues.
            </p>
          </div>
        </div>

        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643559644?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial8}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>

            <p>
              I would recommend this to anyone who is having a problem with
              their device running slowly and am excited for them to try (VRAI)
              this product.
            </p>
          </div>
        </div>

        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643559740?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial9}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              The system analysis was out of this world. I couldn't believe it!
              I will definitely recommend this to all my clients and friends.
            </p>
          </div>
        </div>

        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643559882?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1"
              src={Testmonial1}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              I came in, sat down, they plugged in VRAI, now it (my machine) is
              clean and everything runs better. Super Simple.
            </p>
          </div>
        </div>

        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560151?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial2}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              My machine was pretty sluggish and then VRAI got my machine moving
              much better
            </p>
          </div>
        </div>
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560076?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial3}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              It was so easy and not disruptive at all. We now have a safer a
              faster computer.
            </p>
          </div>
        </div>
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560383?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial4}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              So glad to know I no longer have older (unnecessary) programs
              running in the background.
            </p>
          </div>
        </div>
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560482?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1"
              src={Testmonial5}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              My computer was getting slower over time and I am excited that now
              my computer is going to run at optimal speed.
            </p>
          </div>
        </div>
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560593?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1"
              src={Testmonial6}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              I am looking forward to recommending Crowdpoint (VRAI) to my
              friends.
            </p>
          </div>
        </div>

        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643559790?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial11}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              I already called some friends and told them they need to hurry up
              and come try it.
            </p>
          </div>
        </div>
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643559994?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial12}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              I really like the product and am looking forward to when it will
              be widely available.
            </p>
          </div>
        </div>
        <div>
          <a href="#0"
            id="1"
            variant="primary"
            onClick={(Testmonial1) => handleShow(Testmonial1)}
          >
            <img
              data="https://player.vimeo.com/video/643560362?h=3d25f8f3ea&title=0&byline=0&portrait=0&amp;autoplay=1&mute=1 "
              src={Testmonial13}
              alt="About-img"
            />
          </a>
          <div className="myCarousel">
            <div className="_ss_md_review_start_div">
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
              <span className="fa fa-star checked"></span>
            </div>
            <p>
              Security is so important to me. I have a lot of client information
              on my machine and am so pleased to have a safer laptop.
            </p>
          </div>
        </div>
      </Carousel>

        </div>
      </div>

    </>
  );
}
