import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import Price from "../Components/Pricing";

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
          <>
            <Header />
            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}
            <rs-module-wrap id="aboutus_pg">
              <div className="inner_hdr_section">                     
                <h1 className="title">PLANS</h1>                                        
              </div>
            </rs-module-wrap>

            <div className="lightbg"><Price /></div>

            <Footer />
          </>
      </>
    );
  }
}
