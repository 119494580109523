import React, { Component } from "react";
import icon1 from "../Assets/img/vrai/data-icon1.png";
import privacy from "../Assets/img/vrai/data-home-pic3.png";
import Performance from "../Assets/img/vrai/data-home-pic4.png";
import Protection from "../Assets/img/vrai/data-home-pic6.png";

export default class WhatWeProvide extends Component {
  render() {
    return (

      <div className="section mcb-section mcb-section-2vqahjiyg itsolsec">
        <div className="section_wrapper mcb-section-inner">
          <div className="wrap mcb-wrap mcb-wrap-4dh9er3oi one  valign-top clearfix">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-u5lcwmh16 one column_column column-margin-10px">
                <div className="column_attr clearfix align_center">
                  <div className="image_frame image_item no_link scale-with-grid alignnone no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={icon1} alt="data-icon1" width={15} height={16} /></div>
                  </div>
                  <hr className="no_line hr_spc" />
                  <h6>WHAT WE PROVIDE</h6>
                  {/* <h2 style={{color: '#0d0f39'}}>Personal, Endpoint And Perimeter Network Solutions</h2> */}
                    <h2 className="title_clr">{this.props.title ? this.props.title :"Personal, Endpoint and Perimeter Network Solutions"}</h2>
                </div>
              </div>               
              <div className="column mcb-column mcb-item-axtdttybz one-second column_column">
                <div className="column_attr clearfix align_center" />
              </div>
              <div className="column mcb-column mcb-item-cavgwgbdr one column_divider">
                <hr className="no_line hr_spc2" />
              </div>
            </div>
          </div>
          <div className="wrap mcb-wrap mcb-wrap-krunpr0ng one-third  valign-top clearfix">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-l3750r6u7 one column_column">
                <div className="column_attr clearfix align_center">
                  <div className="image_frame image_item no_link scale-with-grid no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={privacy} alt="data-home-pic3" width={139} height={146} />
                    </div>
                  </div>
                  <hr className="no_line hr_spc3" />
                  <h3 style={{color: '#0d0f39'}}>PRIVACY</h3>
                  <p style={{color: '#a4a6cc'}}>Our Blockchain and IPFS Technology are
                    combined to deliver a Decentralized ID for Privacy.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap mcb-wrap mcb-wrap-3g41sb4kg one-third  valign-top clearfix">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-cjchnhkin one column_column">
                <div className="column_attr clearfix align_center">
                  <div className="image_frame image_item no_link scale-with-grid no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={Performance} alt="data-home-pic4" width={139} height={146} />
                    </div>
                  </div>
                  <hr className="no_line hr_spc3" />
                  <h3 style={{color: '#0d0f39'}}>PERFORMANCE</h3>
                  <p style={{color: '#a4a6cc'}}>Our technology is returning your device
                    to out-of-the-box Performance <br /> Levels.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrap mcb-wrap mcb-wrap-qp8fg7olf one-third  valign-top clearfix">
            <div className="mcb-wrap-inner">
              <div className="column mcb-column mcb-item-7qi7hzxri one column_column">
                <div className="column_attr clearfix align_center">
                  <div className="image_frame image_item no_link scale-with-grid no_border">
                    <div className="image_wrapper"><img className="scale-with-grid" src={Protection} alt="data-home-pic6" width={139} height={146} />
                    </div>
                  </div>
                  <hr className="no_line hr_spc3" />
                  <h3 style={{color: '#0d0f39'}}>PROTECTION</h3>
                  <p style={{color: '#a4a6cc'}}>Our technology will shield your devices and home network from penetrating ransomware and viruses. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
