import React, { Component } from "react";
import Header from "../Components/Common/Header";
import Footer from "../Components/Common/Footer";
import { Modal, Button } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Boston from "../Assets/img/event/boston.jpg";
import PhotoBtn from "../Assets/img/photo-button.png";
import SanAntonio from "../Assets/img/event/SanAntonio.jpg";
import charlotte from "../Assets/img/event/charlotte.jpg";
import phoenix from "../Assets/img/event/phoenix.jpg";
import Boston1 from "../Assets/img/event-popup/Boston/20210430_235456-min.jpg";
import Boston2 from "../Assets/img/event-popup/Boston/20210430_235754-min.jpg";
import Boston3 from "../Assets/img/event-popup/Boston/20210430_235841-min.jpg";
import Boston4 from "../Assets/img/event-popup/Boston/20210430_235850-min.jpg";
import Boston5 from "../Assets/img/event-popup/Boston/20210501_000001-min.jpg";
import Boston6 from "../Assets/img/event-popup/Boston/20210501_000013-min.jpg";
import Boston7 from "../Assets/img/event-popup/Boston/20210501_103940-min.jpg";
import Boston8 from "../Assets/img/event-popup/Boston/20210501_103958-min.jpg";
import Boston9 from "../Assets/img/event-popup/Boston/20210501_105845-min.jpg";
import Phoenix1 from "../Assets/img/event-popup/Phoenix/event-Phoenix.jpg";
import Phoenix2 from "../Assets/img/event-popup/Phoenix/event-Phoenix-1.jpg";
import Charlotte1 from "../Assets/img/event-popup/Charlotte/20210205_170153.jpg";
import Charlotte2 from "../Assets/img/event-popup/Charlotte/20210205_170203.jpg";
import Charlotte3 from "../Assets/img/event-popup/Charlotte/20210205_170209.jpg";
import Charlotte4 from "../Assets/img/event-popup/Charlotte/20210205_170215.jpg";
import Charlotte5 from "../Assets/img/event-popup/Charlotte/20210205_170511.jpg";
import SanAntonio1 from "../Assets/img/event-popup/SanAntonio/20210227_101041.jpg";
import SanAntonio2 from "../Assets/img/event-popup/SanAntonio/20210227_101117.jpg";
import SanAntonio3 from "../Assets/img/event-popup/SanAntonio/20210227_101131.jpg";
import SanAntonio4 from "../Assets/img/event-popup/SanAntonio/20210227_101150.jpg";
import SanAntonio5 from "../Assets/img/event-popup/SanAntonio/20210227_122627.jpg";

const BostonImgs = [
  {
    original: Boston1,
  },
  {
    original: Boston2,
  },
  {
    original: Boston3,
  },
  {
    original: Boston4,
  },
  {
    original: Boston5,
  },
  {
    original: Boston6,
  },
  {
    original: Boston7,
  },
  {
    original: Boston8,
  },
  {
    original: Boston9,
  },
];

const PhoenixImgs = [
  {
    original: Phoenix1,
  },
  {
    original: Phoenix2,
  },
];

const CharlotteImgs = [
  {
    original: Charlotte1,
  },
  {
    original: Charlotte2,
  },
  {
    original: Charlotte3,
  },
  {
    original: Charlotte4,
  },
  {
    original: Charlotte5,
  },
];

const SanAntonioImgs = [
  {
    original: SanAntonio1,
  },
  {
    original: SanAntonio2,
  },
  {
    original: SanAntonio3,
  },
  {
    original: SanAntonio4,
  },
  {
    original: SanAntonio5,
  },
];

export default class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gallery: [],
      showModel: false,
      loading: true,
    };
    this.getGallery = this.getGallery.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = () => this.setState({ showModel: false });
  getGallery = (pics) => {
    this.setState({ showModel: true, gallery: pics });
  };
  getData() {
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <>
          <>
            <Header />

            {/* <header className="pages-banner">
              <div className="container"></div>
            </header> */}

<rs-module-wrap id="aboutus_pg">
              <div className="inner_hdr_section">                     
                <h1 className="title">EVENTS</h1>                                        
              </div>
            </rs-module-wrap>


            <Modal show={this.state.showModel} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>VRAI Videos</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ImageGallery items={this.state.gallery} />;
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  X
                </Button>
              </Modal.Footer>
            </Modal>

            

           

            <section
              id="review"
              className="mt-5 testimonial-area section-padding eve_pg lightbg"
            >
              <div className="container">
                <div className="row">

                  <div className="section-title mb-0">
                    <div className="container">
                      <h2>OUR PAST EVENTS</h2>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="testimonial-slide">
                      <div className="owl-carousel owl-theme">
                        <Carousel
                          selectedItem={0}
                          showArrows={true}
                          showThumbs={false}
                          infiniteLoop={true}
                          showIndicators={true}
                          showStatus={false}
                          autoPlay={true}
                          interval={1300}
                        >
                          <div className="single-testimonial">
                            <div className="testimonial-content-inner about-image">
                              <a>
                                {" "}
                                <img className="circle" src={Boston} alt="Boston" />
                              </a>
                              <p className="city">
                                {" "}
                                <i className="icofont-google-map"></i> Boston
                              </p>

                              <p
                                className="date"
                                onClick={() => {
                                  this.getGallery(BostonImgs);
                                }}
                              >
                                
                                <img
                                  className="gallery_btn"
                                  src={PhotoBtn}
                                  alt="btn"
                                /> 
                              </p>
                            </div>
                          </div>
                          <div className="single-testimonial">
                            <div className="testimonial-content-inner about-image">
                              <a>
                                {" "}
                                <img
                                  className="circle"
                                  src={phoenix}
                                  alt="phoenix"
                                />
                              </a>
                              <p className="city">
                                {" "}
                                <i className="icofont-google-map"></i> Phoenix
                              </p>

                              <p
                                className="date"
                                onClick={() => {
                                  this.getGallery(PhoenixImgs);
                                }}
                              >
                                {" "}
                                <img
                                  className="gallery_btn"
                                  src={PhotoBtn}
                                  alt="img"
                                />{" "}
                              </p>
                            </div>
                          </div>
                          <div className="single-testimonial">
                            <div className="testimonial-content-inner about-image">
                              <a>
                                {" "}
                                <img
                                  className="circle"
                                  src={charlotte}
                                  alt="charlotte"
                                />
                              </a>
                              <p className="city">
                                {" "}
                                <i className="icofont-google-map"></i> Charlotte
                              </p>

                              <p
                                className="date"
                                onClick={() => {
                                  this.getGallery(CharlotteImgs);
                                }}
                              >
                                {" "}
                                <img
                                  className="gallery_btn"
                                  src={PhotoBtn}
                                  alt="btn"
                                />{" "}
                              </p>
                            </div>
                          </div>
                          <div className="single-testimonial">
                            <div className="testimonial-content-inner about-image">
                              <a>
                                {" "}
                                <img
                                  className="circle"
                                  src={SanAntonio}
                                  alt="SanAntonio"
                                />
                              </a>
                              <p className="city">
                                {" "}
                                <i className="icofont-google-map"></i> San Antonio
                              </p>

                              <p
                                className="date"
                                onClick={() => {
                                  this.getGallery(SanAntonioImgs);
                                }}
                              >
                                {" "}
                                <img
                                  className="gallery_btn"
                                  src={PhotoBtn}
                                  alt="btn"
                                />{" "}
                              </p>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Footer />
          </>
      </>
    );
  }
}
